import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Group from "../../assets/svgs/Group.svg";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import { setNewFinalTestAnswerRequest } from "../../store/FinalTest/actions";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import {
  SET_FINAL_TEST_MODE,
  SET_FINAL_TEST_WRAPPER_MODE
} from "../../store/Utilities/types";
import {
  FinalTestMode,
  FinalTestWrapperViewMode
} from "../../utils/Constants";

function FinalTest({
  book_id,
  test_view_mode,
  finalTest,
  isModal,
  isProgram
}) {
  const { messages } = useIntl();
  const {
    FinalTest: {
      allQuestionRequired,
      explanation,
      note,
      notedescription,
      FinalTest,
      courseTests
    }
  } = messages;
  const dispatch = useDispatch();
  const history = useHistory();

  const [ImageModalTest, setImageModalTest] = useState(null);
  const [allTestAnswers, setAllTestAnswers] = useState([]);

  const collectAnswers = async (questionId, answer) => {
    const questionExist = allTestAnswers.find(
      (ans) => ans.question_id === questionId
    );

    let payload = {
      question_id: questionId,
      answer_id: answer
    };

    if (questionExist) {
      setAllTestAnswers((oldAnswers) =>
        oldAnswers.map((ans) =>
          ans.question_id === questionId
            ? { ...ans, answer_id: answer }
            : ans
        )
      );
    } else {
      setAllTestAnswers((oldAnswers) => [...oldAnswers, payload]);
    }
  };
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_FINAL_TEST_MODE,
        payload: FinalTestMode.View
      });
      dispatch({
        type: SET_FINAL_TEST_WRAPPER_MODE,
        payload: FinalTestWrapperViewMode.ViewScore
      });
    };
  }, []);
  const submitAnswers = async () => {
    if (
      allTestAnswers.length === finalTest?.details?.questions?.length
    ) {
      dispatch(
        setNewFinalTestAnswerRequest({
          book_id: book_id,
          answers: allTestAnswers
        })
      );
      if (isModal) {
        dispatch({
          type: SET_FINAL_TEST_WRAPPER_MODE,
          payload: FinalTestWrapperViewMode.ViewScore
        });
      } else {
        history.push(
          `${ROUTE_PATHS.FinalTest}/${FinalTestWrapperViewMode.ViewScore}/${book_id}`
        );
      }
    } else {
      dispatch(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: allQuestionRequired
        })
      );
    }
  };

  const handleTestButtonClick = () => {
    let route = `${ROUTE_PATHS["bookAllTests"]}/${book_id}`;
    if (isProgram)
      route = `${ROUTE_PATHS.programsBookAllTests}/${book_id}`;
    history.push(route);
  };
  const isView = test_view_mode === "View";
  console.log("finalTest", finalTest);

  return (
    <>
      <div className="mx-auto w-80 relative">
        {!isModal && (
          <div className="my-auto d-flex">
            <BackButton
              className="my-auto me-4 backButton"
              icon={"b"}
              otherWise={handleTestButtonClick}
            />
            <span className="headerText">{courseTests}</span>
          </div>
        )}
        <Typography
          id="modal-modal-title"
          variant="h5"
          className="text-center text-primary mb-4"
        >
          {`${finalTest?.book_title} ${FinalTest}`}
        </Typography>

        {finalTest?.note && (
          <div className="note gray mb-3">
            <span className="grey bold"> {note} </span>
            {`${finalTest?.note} , ${notedescription}`}
          </div>
        )}
        {ImageModalTest && (
          <Lightbox
            mainSrc={ImageModalTest}
            onCloseRequest={() => setImageModalTest(null)}
          />
        )}
        {finalTest?.details?.questions.map((question, questionId) => (
          <div className="mb-2" key={questionId}>
            <div className=" mb-2">
              <div className="d-flex align-items-baseline">
                <h4 className="me-2 mb-0">Q{questionId + 1}-</h4>
                <h4 className="mb-0 ">{question.question}</h4>
              </div>

              {question.image_url && (
                <div
                  className="d-flex container_image_question mb-2"
                  onClick={() =>
                    setImageModalTest(question.image_url)
                  }
                >
                  <img
                    src={question.image_url}
                    className="img-thumbnail question-image"
                  />
                  <div className="middle">
                    <div className="text">
                      <img
                        height="20px"
                        src={Group}
                        alt="medblend-image"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center flex-column ">
              <ol type="A">
                {question?.answer_choices?.map(
                  (
                    { answer, answer_choice_id, image_url, correct },
                    index
                  ) => {
                    console.log(
                      ">>>",
                      answer,
                      answer_choice_id,
                      image_url,
                      correct
                    );

                    const userAnswer =
                      finalTest?.user_answers?.answers?.find(
                        (answer) =>
                          answer.answer_id === answer_choice_id
                      );
                    return (
                      <li key={index}>
                        <div className="mb-2 answer d-flex align-items-start">
                          <input
                            id={`${question.question_id}_${answer_choice_id}`}
                            type="radio"
                            value={answer}
                            disabled={
                              isView ? true : false || isProgram
                            }
                            className="mt-2 cursor-pointer mr-11"
                            name={`question_${questionId}`}
                            checked={
                              isView
                                ? userAnswer?.answer_id ===
                                    answer_choice_id &&
                                  userAnswer?.correct === correct
                                : undefined
                            }
                            onChange={() => {
                              console.log(
                                "question",
                                question,
                                answer_choice_id
                              );

                              collectAnswers(
                                question.question_id,
                                answer_choice_id
                              );
                            }}
                          />
                          {image_url && (
                            <div
                              className="container_image_answer"
                              onClick={(e) => {
                                setImageModalTest(image_url);
                              }}
                            >
                              <img
                                src={image_url}
                                width="100px"
                                className="img-thumbnail answer-image"
                                alt="answer"
                              />
                              <div className="middle">
                                <div className="text">
                                  <img
                                    height="20px"
                                    src={Group}
                                    alt="medblend-image"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <label
                            key={answer_choice_id}
                            className="cursor-pointer"
                            htmlFor={`${question.question_id}_${answer_choice_id}`}
                          >
                            <span className="answer-text">
                              {answer}
                            </span>
                          </label>
                        </div>
                      </li>
                    );
                  }
                )}
                {isView &&
                  !!finalTest?.user_answers?.answers?.length &&
                  question?.justification && (
                    <div className="explanation my-3">
                      <p>
                        {explanation}:{` ${question?.justification}`}
                      </p>

                      {question.justification_image_url && (
                        <div
                          className="container_image_justification"
                          style={{ width: "200px" }}
                          onClick={(e) => {
                            setImageModalTest(
                              question.justification_image_url
                            );
                          }}
                        >
                          <img
                            src={question.justification_image_url}
                            width="200px"
                            className="img-thumbnail justfication-image"
                            alt="answer"
                          />
                          <div className="middle">
                            <div className="text">
                              <img
                                height="20px"
                                src={Group}
                                alt="medblend-image"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </ol>
            </div>
          </div>
        ))}
        <div className={`d-flex justify-content-end mt-2`}>
          <Button
            className={
              isView || isProgram ? "disaleSubmit" : "primary"
            }
            disabled={isView ? true : false || isProgram}
            onClick={submitAnswers}
            type="button"
            text="Submit"
          />
        </div>
      </div>
      {/* )} */}
    </>
  );
}
FinalTest.propTypes = {
  book_id: PropTypes.any.isRequired,
  finalTest: PropTypes.object.isRequired,
  test_view_mode: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
  isProgram: PropTypes.bool
};
export default FinalTest;
