import {
  author_portal,
  axiosInstance,
  customer_portal,
  editor_portal,
  program_director_portal
} from "./index";

export const login = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/sessions`,
    payload
  );
};
export const loginAuthor = async (payload) => {
  return await axiosInstance.post(
    `${author_portal}/sessions`,
    payload
  );
};
export const loginEditor = async (payload) => {
  return await axiosInstance.post(
    `${editor_portal}/sessions`,
    payload
  );
};
export const loginProgramDirector = async (payload) => {
  return await axiosInstance.post(
    `${program_director_portal}/sessions`,
    payload
  );
};
export const logout = async () => {
  return await axiosInstance.delete(`${customer_portal}/sessions`);
};
export const authorLogout = async () => {
  return await axiosInstance.delete(`${author_portal}/sessions`);
};
export const editorLogout = async () => {
  return await axiosInstance.delete(`${editor_portal}/sessions`);
};
export const programLogout = async () => {
  return await axiosInstance.delete(
    `${program_director_portal}/sessions`
  );
};
export const socialAuthorization = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/users/social_registration`,
    payload,
    { handlerEnabled: false }
  );
};

export const register = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/users`,
    payload
  );
};

export const userDetails = async () => {
  return await axiosInstance.get(`${customer_portal}/users`);
};

export const verifyUser = async (payload) => {
  return await axiosInstance.patch(
    `${customer_portal}/users/verify_email`,
    payload
  );
};

export const resendVerificationEmail = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/users/resend_email_verification`,
    payload
  );
};

export const editUserInfo = async (payload) => {
  return await axiosInstance.patch(
    `${customer_portal}/users/update_profile`,
    payload
  );
};

export const changePassword = async (payload) => {
  return await axiosInstance.patch(
    `${customer_portal}/users/update_password`,
    payload
  );
};
export const changeAuthorsPassword = async (payload) => {
  return await axiosInstance.patch(
    `${author_portal}/authors/${payload.userId}/change_password`,
    payload
  );
};
export const changeEditorsPassword = async (payload) => {
  return await axiosInstance.patch(
    `${editor_portal}/editors/${payload.userId}/change_password`,
    payload
  );
};
export const changeProgramDirectorsPassword = async (payload) => {
  return await axiosInstance.patch(
    `${program_director_portal}/program_directors/${payload.userId}/change_password`,
    payload
  );
};

export const restNewPassword = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/users/reset_password`,
    payload
  );
};

export const sendEmailNewPassword = async (payload) => {
  return await axiosInstance.post(
    `${customer_portal}/users/forgot_password`,
    payload
  );
};
