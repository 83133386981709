import { Box, Modal } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_OPEN_FINAL_TEST_MODAL } from "../../../store/Utilities/types";
import { boxStyle } from "../../../containers/Books/BookViewer/boxStyle";
import FinalTestWraper from "../../../containers/FinalTest/FinalTestWraper";
import PropTypes from "prop-types";

function FinalTestModal({ book_id }) {
  const { showFinalTest } = useSelector((state) => state.utilities);
  const dispatch = useDispatch();
  const setOpenFinalTest = (open) => {
    dispatch({
      type: SET_OPEN_FINAL_TEST_MODAL,
      payload: open
    });
  };
  return (
    <Modal
      open={showFinalTest}
      onClose={() => {
        setOpenFinalTest(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...boxStyle,
          maxHeight: "90%",
          height: "90%",
          width: "45%",
          minWidth: "45%",
          overflowY: "auto"
        }}
      >
        <FinalTestWraper bookID={book_id} isModal={true} />
      </Box>
    </Modal>
  );
}
FinalTestModal.propTypes = {
  book_id: PropTypes.any,
  finalTest: PropTypes.object
};

export default FinalTestModal;
