import * as types from "./types";

const INITIAL_STATE = {
  resList: [],
  searchKeyword: ""
};

const studentsListReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case types.GET_STUDENTSLIST_RESPONSE:
      return {
        ...state,
        resList: actions.payload.resList
      };
    default:
      return state;
  }
};

export default studentsListReducer;
