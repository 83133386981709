import React from "react";
import { Container } from "@mui/material";
import "./StudentsList.scss";

import TableStudents from "./TableStudents";
import StudentsOrTopics from "../../components/StudentsOrTopics";

function StudentsList() {
  return (
    <>
      <Container maxWidth="xl">
        <StudentsOrTopics />
        <TableStudents />
      </Container>
    </>
  );
}

export default StudentsList;
