import React from "react";
import BackButton from "../../../components/BackButton";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CircularProgressbar } from "react-circular-progressbar";
import Button from "../../../components/Button";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { SET_FINAL_TEST_MODE } from "../../../store/Utilities/types";
import { FinalTestMode } from "../../../utils/Constants";

function ViewScoreFinalTest({
  book_id,
  finalTestAnswers,
  isModal,
  viewFinalTestFunc
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    FinalTest: {
      FinalTest,
      reTakeFinalTest,
      viewFinalTest,
      success,
      failed,
      bestScoreAchieved,
      courseTests
    },
    buttons: { ok }
  } = messages;

  const setModeFinalTest = (mode) => {
    dispatch({
      type: SET_FINAL_TEST_MODE,
      payload: mode
    });
  };

  const handleBackButtonClick = () => {
    const route = `${ROUTE_PATHS["bookViewer"]}/${book_id}`;
    history.push(route);
  };
  const handleBackToRetakeFinalTest = () => {
    if (isModal) setModeFinalTest(FinalTestMode.Take);
    viewFinalTestFunc?.(FinalTestMode.Take);
  };
  const handleBackToViewFinalTest = () => {
    if (isModal) setModeFinalTest(FinalTestMode.View);
    viewFinalTestFunc?.(FinalTestMode.View);
  };
  const handleTestButtonClick = () => {
    const route = `${ROUTE_PATHS["bookAllTests"]}/${book_id}`;
    history.push(route);
  };
  return (
    <>
      <div className={`mx-auto w-80 relative`}>
        {!isModal && (
          <div className="my-auto d-flex">
            <BackButton
              className="my-auto me-4 backButton"
              icon={"b"}
              otherWise={handleTestButtonClick}
            />
            <span className="headerText">{courseTests}</span>
          </div>
        )}

        <Typography
          id="modal-modal-title"
          variant="h5"
          className="text-center text-primary mb-4"
        >
          {`${finalTestAnswers?.book_title} ${FinalTest}`}
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="h6"
          className="text-center text-primary mb-4"
        >
          {bestScoreAchieved}
        </Typography>
        {finalTestAnswers?.user_answers?.score > 50 ? (
          <p className="success text-center my-4">{success}</p>
        ) : (
          <p className="error text-center my-4">{failed}</p>
        )}
        <div className="precentage">
          <CircularProgressbar
            value={Math.round(finalTestAnswers?.user_answers?.score)}
            text={`${Math.round(
              finalTestAnswers?.user_answers?.score
            )}%`}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className="my-3">
          <Button
            text={reTakeFinalTest}
            className="continue_btn"
            onClick={() => {
              handleBackToRetakeFinalTest();
            }}
          />
        </div>
        <div className=" my-3">
          <Button
            text={viewFinalTest}
            className="continue_btn"
            onClick={() => {
              handleBackToViewFinalTest();
            }}
          />
        </div>

        {!isModal && (
          <div className="my-3">
            <Button
              text={ok}
              onClick={() => {
                handleBackButtonClick();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
ViewScoreFinalTest.propTypes = {
  book_id: PropTypes.any,
  finalTestAnswers: PropTypes.object,
  isModal: PropTypes.bool,
  viewFinalTestFunc: PropTypes.func
};

export default ViewScoreFinalTest;
