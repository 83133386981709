import React from "react";
import { Slider, Box, Typography, Link } from "@mui/material";
import PropTypes from "prop-types";

function ProgressbarMatrialUi({
  chapterName,
  value,
  max,
  finalTestScore
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <Typography
          variant="body2"
          sx={{ mr: 2 }}
        >{`${value}%`}</Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "center", flex: 1 }}
        >
          {chapterName}
        </Typography>

        <Typography variant="body2" sx={{ ml: 2 }}>{`${
          max - value
        } left`}</Typography>
      </Box>
      <Slider
        value={value}
        max={max}
        sx={{
          width: "100%",
          height: 8,
          "& .MuiSlider-track": {
            backgroundColor: "#e39ed7",
            border: "none"
          },
          "& .MuiSlider-thumb": {
            backgroundColor: "#f0c3e0",
            width: 16,
            height: 16
          },
          "& .MuiSlider-rail": {
            backgroundColor: "#ddd"
          }
        }}
        aria-labelledby="progress-bar"
      />
      <Box
        sx={{
          mt: 1
        }}
      >
        <Link
          variant="body2"
          sx={{
            textDecoration: "none",
            textAlign: "center",
            flex: 1
          }}
        >
          {`Final test score: ${finalTestScore}%`}
        </Link>
      </Box>
    </Box>
  );
}
ProgressbarMatrialUi.propTypes = {
  courseName: PropTypes.string.isRequired,
  chapterName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  finalTestScore: PropTypes.number.isRequired
};

ProgressbarMatrialUi.defaultProps = {
  courseName: "Default Course Name" // Optional default value
};
export default ProgressbarMatrialUi;
