import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";
import propTypes from "prop-types";
import { useIntl } from "react-intl";

function ProgressTable({ books }) {
  const { messages } = useIntl();
  const {
    studentsList: {
      TableProgressHeader: { courseName, chapterName, score }
    }
  } = messages;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mb: "100px"
      }}
    >
      <TableContainer
        sx={{
          width: "80%",
          boxShadow: "none",
          border: "none",
          borderRadius: "5px"
        }}
      >
        <Table
          sx={{
            borderCollapse: "collapse",
            border: "none"
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: "#9F9C9C", borderBottom: "none" }}
              >
                {courseName}
              </TableCell>
              <TableCell
                sx={{ color: "#9F9C9C", borderBottom: "none" }}
              >
                {chapterName}
              </TableCell>
              <TableCell
                sx={{ color: "#9F9C9C", borderBottom: "none" }}
              >
                {score}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books?.map((course, courseIndex) => (
              <React.Fragment key={courseIndex}>
                <TableRow
                  key={0}
                  sx={{
                    backgroundColor: "#FCFBFE",
                    borderBottom: "none"
                  }}
                >
                  <TableCell
                    sx={{
                      alignContent: "flex-start",
                      verticalAlign: "top"
                    }}
                  >
                    <h6>{course.book.title}</h6>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {course.tests[0]?.test?.title || "none"}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {course.tests[0]?.test_report?.post_test_score ||
                      "N/A"}
                  </TableCell>
                </TableRow>
                {course.tests.map((test, testIndex) => (
                  <TableRow
                    key={testIndex}
                    sx={{
                      backgroundColor: "#FCFBFE",
                      borderBottom: "none"
                    }}
                  >
                    <TableCell></TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {test?.test?.title || "N/A"}
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {test?.test_report?.post_test_score || "N/A"}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell
                    sx={{
                      borderRadius: "7px 0 0 7px",
                      bgcolor: "#eebce9"
                    }}
                  >
                    Final Test
                  </TableCell>
                  <TableCell sx={{ bgcolor: "#eebce9" }} />
                  <TableCell
                    sx={{
                      borderRadius: "0 7px 7px 0",
                      bgcolor: "#eebce9"
                    }}
                  >
                    {course?.score || "N/A"}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

ProgressTable.propTypes = {
  books: propTypes.array.isRequired
};

export default ProgressTable;
