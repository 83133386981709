import React from "react";

function DeleteIconTopic() {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.36 26.2833C3.61333 26.2833 2.97778 26.0211 2.45333 25.4967C1.92889 24.9722 1.66667 24.3372 1.66667 23.5917V2.95H0V1.28333H6.66667V0H16.6667V1.28333H23.3333V2.95H21.6667V23.5917C21.6667 24.3583 21.41 24.9989 20.8967 25.5133C20.3833 26.0278 19.7422 26.2844 18.9733 26.2833H4.36ZM20 2.95H3.33333V23.5917C3.33333 23.8906 3.42944 24.1361 3.62167 24.3283C3.81389 24.5206 4.06 24.6167 4.36 24.6167H18.975C19.2306 24.6167 19.4656 24.51 19.68 24.2967C19.8944 24.0833 20.0011 23.8478 20 23.59V2.95ZM8.01333 21.2833H9.68V6.28333H8.01333V21.2833ZM13.6533 21.2833H15.32V6.28333H13.6533V21.2833Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}

export default DeleteIconTopic;
