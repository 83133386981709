import * as types from "./types";

const INITIAL_STATE = {
  loading: false,
  finalTest: {},
  resultOfAnswer: {}
};

const FinalTestReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case types.GET_FINAL_TEST_RESPONSE:
      return {
        ...state,
        finalTest: actions.payload.finalTest
      };
    case types.SET_NEW_FINAL_TEST_ANSWER__RESPONSE:
      return {
        ...state,
        resultOfAnswer: actions.payload.resultOfAnswer
      };
    default:
      return state;
  }
};

export default FinalTestReducer;
