import * as types from "./types";

const INITIAL_STATE = {
  loading: false,
  currentProgram: {},
  TopicsList: [],
  Topic: {}
};

const TopicsReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case types.ADD_TOPIC_REQUEST:
      return { ...state, loading: true };

    case types.ADD_TOPIC_RESPONSE:
      return { ...state, loading: false };

    case types.GET_CURRENT_PROGRAM_RESPONCE:
      return {
        ...state,
        currentProgram: actions.payload.currentProgram
      };

    case types.GET_ALL_TOPICS_RESPONCE:
      return {
        ...state,
        TopicsList: actions.payload.data.TopicsList
      };

    case types.GET_SINGLE_TOPIC_RESPONCE:
      return {
        ...state,
        Topic: actions.payload.Topic
      };

    case types.DELETE_SINGLE_TOPIC_RESPONCE:
      return {
        ...state,
        TopicsList: state.TopicsList.filter(
          (topic) => topic.id !== actions.payload.topicId
        )
      };

    default:
      return state;
  }
};

export default TopicsReducer;
