import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Input, Modal } from "@mui/material";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
  addCustomerCommentRequest,
  addDirectorCommentRequest,
  deleteCustomerCommentRequest,
  deleteDirectorCommentRequest,
  getSingleTopicRequest,
  getSingleTopicStudentRequest
} from "../../store/Topics/actions";
import { boxStyle } from "../Books/BookViewer/boxStyle";
import SingleComment from "./SingleComment";
import "./topic.scss";
import StudentsOrTopics from "../../components/StudentsOrTopics";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";

function DetialsTopic() {
  const { program_id, id } = useParams();
  const dispatch = useDispatch();
  const Topic = useSelector((state) => state.topics.Topic);
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");

  const [openDelete, setOpenDelete] = useState({
    open: false,
    id: 0
  });

  useEffect(() => {
    isProgram
      ? dispatch(getSingleTopicRequest(program_id, id))
      : dispatch(getSingleTopicStudentRequest(program_id, id));
  }, [program_id, id]);

  const [message, setMessage] = useState("");

  const addComment = () => {
    const data = {
      programId: program_id,
      topicId: id,
      comment: {
        topic_comment: {
          content: message
        }
      }
    };
    isProgram
      ? dispatch(addDirectorCommentRequest(data))
      : dispatch(addCustomerCommentRequest(data));
    setMessage("");
  };
  const handleEnterKey = (e) =>
    e.key === "Enter" ? addComment() : null;

  const triggerDeleteModal = (id) => {
    setOpenDelete({
      id,
      open: true
    });
  };
  const closeDeleteModal = () =>
    setOpenDelete({
      id: 0,
      open: false
    });

  const deleteComment = () => {
    const data = {
      programId: program_id,
      topicId: id,
      commentId: openDelete.id
    };
    isProgram
      ? dispatch(deleteDirectorCommentRequest(data))
      : dispatch(deleteCustomerCommentRequest(data));
    closeDeleteModal();
  };
  const history = useHistory();
  const goToEditTopic = () => {
    history.push(`/programs/${program_id}/topic/EditOldTopic/${id}`);
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            width: "80%",
            paddingBottom: "100px",
            height: "100%"
          }}
        >
          {" "}
          <StudentsOrTopics />
          <h5>Topic Name : {Topic.title}</h5>
          <div
            style={{
              padding: "5px",
              width: "100%",
              height: "auto",
              border: "1px solid  #7D7D7D",
              borderRadius: "10px",
              wordWrap: "break-word",
              WebkitBoxOrient: "vertical",
              overflowY: "hidden",
              textOverflow: "ellipsis",
              color: "#8D8D8D"
            }}
          >
            {Topic?.content
              ? parse(Topic.content, {
                  replace: (domNode) => {
                    if (domNode.name === "img") {
                      domNode.attribs.style =
                        "max-width: 500px; height: auto;";
                    }
                  }
                })
              : "No content available"}
            <div
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip title="Edit Topic" arrow>
                <EditIcon
                  onClick={goToEditTopic}
                  className="editToic-icon"
                />
              </Tooltip>
            </div>
          </div>
          <br />
          {Topic?.comments?.map((comment, index) => (
            <SingleComment
              triggerDeleteModal={triggerDeleteModal}
              key={index}
              comment={comment}
            />
          ))}
          <div className="new-comment">
            <Input
              style={{ width: "100%" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={"Add New Comment"}
              onKeyDown={handleEnterKey}
            />
            <FontAwesomeIcon
              onClick={addComment}
              className="arrow"
              icon={faPlay}
            />
          </div>
        </Box>
      </Container>
      <Modal
        open={openDelete.open}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...boxStyle,
            maxHeight: "90%",
            height: "40svh",
            width: "45%",
            minWidth: "45%",
            overflowY: "auto",
            border: "1px solid black",
            display: "flex",
            flexWrap: "wrap"
          }}
        >
          <h4>Confirm Delete This Comment!</h4>
          <br />
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "end"
            }}
          >
            <Button
              variant="contained"
              style={{ color: "white" }}
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="error"
              onClick={deleteComment}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default DetialsTopic;
