import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router";
import { Typography } from "@mui/material";

import {
  getCertainQuiz,
  setCertainQuiz
} from "../../store/Books/actions";
import { axiosInstance, customer_portal } from "../../network/apis";
import Group from "../../assets/svgs/Group.svg";
import "./QuizDisplay.module.scss";
import BackButton from "../../components/BackButton";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const QuizDisplay = () => {
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isProgram = pathname.includes("program");
  const { id, bookId, bookPage } = useParams();
  const { messages } = useIntl();
  const {
    quiz: { notedescription, note }
  } = messages;
  // eslint-disable-next-line no-unused-vars
  const [questionAnswers, setQuestionAnswer] = useState();
  const [userAnswers, setUserAnswers] = useState({});
  const [ImageModalTest, setImageModalTest] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    books: { quizDetails }
  } = useSelector((state) => state);
  useEffect(() => {
    if (quizDetails) {
      setQuestionAnswer(quizDetails);
      const oldAnswers = {};
      quizDetails?.questions?.forEach((question) => {
        if (question?.user_answer) {
          oldAnswers[question.user_answer?.question_id] =
            question?.user_answer?.answer_choice_id;
        }
      });
      setUserAnswers(oldAnswers);
    }
  }, [quizDetails]);

  useEffect(async () => {
    if (id) {
      await dispatch(
        getCertainQuiz({
          id: id,
          bookId: bookId,
          page: bookPage,
          isProgram
        })
      );
    }
  }, [id, bookId, bookPage]);

  const addUsersAnswer = (id, answer) => {
    const answers = { ...userAnswers };
    answers[id] = answer;
    setUserAnswers(answers);
  };

  const handleTestButtonClick = () => {
    const route = `${ROUTE_PATHS["bookAllTests"]}/${bookId}`;
    history.push(route);
  };
  return (
    <div className="mx-auto w-80">
      <div className="my-auto d-flex">
        <BackButton
          className="my-auto me-4 backButton"
          icon={"b"}
          otherWise={handleTestButtonClick}
        />
        <span className="headerText">Course Tests</span>
      </div>
      {ImageModalTest && (
        <Lightbox
          mainSrc={ImageModalTest}
          onCloseRequest={() => setImageModalTest(null)}
        />
      )}
      <div className="d-flex justify-content-between">
        <span></span>
        <Typography
          id="modal-modal-title"
          variant="h5"
          className="text-center text-primary "
        >
          {quizDetails?.title}
        </Typography>
      </div>
      <div className="text-secondary my-3">
        Score:{" "}
        {quizDetails?.questions?.[0]?.user_answer?.user_gained_points
          ? quizDetails?.questions?.[0]?.user_answer
              ?.user_gained_points
          : 0}
        /{quizDetails?.total_points}
      </div>
      {quizDetails?.questions?.find(
        (qs) =>
          !!qs?.image_url ||
          qs?.answer_choices?.some((ans) => ans.image_url)
      ) && (
        <div className="note gray mb-3">
          <span className="grey bold">{note} </span>
          {notedescription}
        </div>
      )}

      <div>
        {quizDetails?.questions?.map((question, i) => {
          return (
            <div className="my-3" key={question.id}>
              <div className="d-flex align-items-baseline">
                <h4 className="me-2 mb-0">Q{i + 1}-</h4>
                <h4 className="mb-0">{question.question}</h4>
              </div>
              {question.image_url && (
                <div
                  className="container_image_question mb-2"
                  onClick={() =>
                    setImageModalTest(question.image_url)
                  }
                >
                  <img
                    src={question.image_url}
                    className="img-thumbnail question-image"
                  />
                  <div className="middle">
                    <div className="text">
                      <img src={Group} alt="medblend-image" />
                    </div>
                  </div>
                </div>
              )}
              <ol type="A">
                {question.answer_choices.map(
                  ({ answer, id: answerId, image_url, correct }) => (
                    <li key={answerId}>
                      <div className="my-2 d-flex align-items-center">
                        <label
                          className="cursor-pointer d-flex align-items-start answer"
                          htmlFor={
                            !image_url
                              ? `MCQ_${question.id}_${answer}`
                              : ""
                          }
                        >
                          <input
                            id={`MCQ_${question.id}_${answer}`}
                            type="radio"
                            value={answer}
                            disabled={
                              userAnswers?.[question.id] ||
                              isAuthor ||
                              isProgram
                            }
                            className="m-2"
                            name={`question_${i}`}
                            checked={
                              userAnswers?.[question.id] === answerId
                            }
                            onChange={async () => {
                              try {
                                await axiosInstance.post(
                                  `${customer_portal}/questions/${question.id}/quiz_question_answer`,
                                  {
                                    answer_choice_id: answerId,
                                    book_quiz_id:
                                      quizDetails?.book_quiz_id
                                  }
                                );
                                await dispatch(
                                  getCertainQuiz({
                                    id: id,
                                    bookId: bookId,
                                    page: bookPage
                                  })
                                );
                              } catch (error) {
                                //
                              }
                              addUsersAnswer(question.id, answerId);
                            }}
                          />
                          {image_url && (
                            <div
                              className="container_image_answer"
                              onClick={() =>
                                setImageModalTest(image_url)
                              }
                            >
                              <img
                                src={image_url}
                                width="100px"
                                className="img-thumbnail answer-image"
                                alt="answer"
                              />
                              <div className="middle">
                                <div className="text">
                                  <img
                                    height="20px"
                                    src={Group}
                                    alt="medblend-image"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <span className="answer-text">
                            {answer}
                          </span>
                        </label>
                        <div className="result">
                          {userAnswers?.[question.id] === answerId ? (
                            <>
                              {correct && (
                                <div className="success">correct</div>
                              )}
                              {correct === false && (
                                <div className="error">wrong</div>
                              )}
                            </>
                          ) : (
                            userAnswers?.[question.id] &&
                            correct && (
                              <div className="success">correct </div>
                            )
                          )}
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ol>
              {userAnswers?.[question.id] && question.justification && (
                <>
                  <div className="explanation  my-3">
                    <p>{question.justification}</p>
                    {question.justification_image_url && (
                      <div style={{ width: "30vw" }}>
                        <img
                          style={{
                            width: "100%",
                            height: "100%"
                          }}
                          src={question.justification_image_url}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuizDisplay;
