import * as types from "./types";

export const getStudentsListRequest = () => ({
  type: types.GET_STUDENTSLIST_REQUEST
});

export const getStudentsListResponse = (data) => ({
  type: types.GET_STUDENTSLIST_RESPONSE,
  payload: data
});
