import React from "react";
import "./SingleRow.scss";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProgressbarMatrialUi from "../../../components/ProgressbarMatrialUi";
import { Paper } from "@mui/material";
import imgTest from "../../../assets/images/Generic avatar.png";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/PathsNames";

function SingleRow(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const findActiveCourse = (row) => {
    let activeCourse = "";
    let latestDate = 0;

    for (let i = 0; i < row.books.length; i++) {
      const currentBook = row.books[i];
      let dateOfCourse = new Date(
        currentBook.book.release_date
      ).getTime();

      if (dateOfCourse > latestDate) {
        latestDate = dateOfCourse;
        activeCourse = currentBook.book.title;
      }
    }

    return activeCourse;
  };

  const calculatePercentage = (totalChapters, passedChapters) => {
    if (totalChapters === 0) return 0;
    return (passedChapters / totalChapters) * 100;
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", backgroundColor: "#FCFBFE" }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            borderBottom: "none"
          }}
        >
          <Link
            to={{
              pathname: `${ROUTE_PATHS.StudentProgress}/${row.user.id}`
            }}
          >
            <img
              src={row.user.image ? row.user.image : imgTest}
              className="me-1 rounded-circle"
              width={30}
              height={30}
              alt="student image"
            />
            {row.user.first_name + " " + row.user.second_name}
          </Link>
        </TableCell>
        <TableCell sx={{ borderBottom: "none" }}>
          {findActiveCourse(row) || "none"}
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: "none" }}>
          {row.books
            .map((item) => {
              const lastChapter = item.tests.reduce((acc, test) => {
                if (test.test_report?.pre_test_score) {
                  acc = test.test.title;
                }
                return acc;
              }, "none");

              return lastChapter;
            })
            .join(", ")}
        </TableCell>
        <TableCell align="right" sx={{ borderBottom: "none" }}>
          <span>Details</span>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: "none" }}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "none"
          }}
          colSpan={4}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table
                size="small"
                aria-label="purchases"
                component={Paper}
                sx={{
                  border: "none",
                  boxShadow: "none"
                }}
              >
                <TableBody>
                  {row.books.map((item, index) => {
                    let totalChapters = 0;
                    let passedChapters = 0;

                    item.tests.forEach((test) => {
                      totalChapters++;
                      if (
                        test.test_report &&
                        test.test_report.post_test_score &&
                        test.test_report.pre_test_score
                      ) {
                        passedChapters++;
                      }
                    });
                    const lastChapter = item.tests.reduce(
                      (acc, test) => {
                        if (test.test_report?.pre_test_score) {
                          acc = test.test.title;
                        }
                        return acc;
                      },
                      "none"
                    );

                    const percentage = calculatePercentage(
                      totalChapters,
                      passedChapters
                    );

                    return (
                      <TableRow
                        key={index}
                        sx={{ borderBottom: "none" }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ borderBottom: "none" }}
                        >
                          {item.book.title}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }} />
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ borderBottom: "none" }}
                        >
                          <div
                            style={{
                              width: "100%",
                              margin: "0px auto"
                            }}
                          >
                            <ProgressbarMatrialUi
                              chapterName={lastChapter}
                              value={percentage}
                              max={100}
                              finalTestScore={item.final_score}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

SingleRow.propTypes = {
  row: PropTypes.shape({
    user: PropTypes.object.isRequired,
    books: PropTypes.array.isRequired
  }).isRequired
};

export default SingleRow;
