import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isScollbarClicked } from "../../hooks/useOutSideClickDetector";
import { dateToDashed } from "../../utils";
import { writtenBy } from "../../utils/general";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import BookCover from "../BookCover";
import Button from "../Button";
import "./ClickableBookCover.scss";

const BooksNameAndAuthor = ({ book, isSelected }) => {
  const { title, author, release_date, price } = book;
  const { locale } = useIntl();
  return (
    <>
      <h5
        className={`clickable-book__book-title ${
          isSelected ? "selected" : ""
        }`}
      >
        {title}
      </h5>
      <div className="d-flex justify-content-between">
        <p className="clickable-book__author-name black-mid">
          {writtenBy(author)}
        </p>
        {price && <div className="priceDiv">${price}</div>}
      </div>
      {isSelected && (
        <span>
          {" "}
          {release_date && <>{dateToDashed(release_date)}</>}
        </span>
      )}
    </>
  );
};

BooksNameAndAuthor.propTypes = {
  book: PropTypes.object,
  isSelected: PropTypes.bool
};

const ClickableBookCover = ({
  isSelected,
  book,
  selectBook,
  clearSelection
}) => {
  const { messages } = useIntl();
  const { pathname } = useLocation();
  const isEditor = pathname.includes("editor");
  const isProgram = pathname.includes("program");
  const {
    buttons: { getAccess }
  } = messages;

  const wrapperRef = useRef(null);
  const [wrapperStyle, setWrapperStyle] = useState({});
  useEffect(() => {
    if (!isSelected) {
      if ("ontouchstart" in window && wrapperRef.current) {
        setWrapperStyle({ height: "16rem" });
      } else setWrapperStyle({ height: "14rem" });
    } else setWrapperStyle({ height: null, paddingBottom: "1rem" });
  }, [isSelected]);

  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if ("ontouchstart" in window && wrapperRef.current) {
      function handleClickOutside(event) {
        if (
          clicked &&
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target) &&
          !isScollbarClicked(event)
        ) {
          setClicked(false);
          clearSelection();
        } else {
          setClicked(true);
        }
      }
      // Bind the event listener
      document.addEventListener("touchend", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("touchend", handleClickOutside);
      };
    }
  }, []);
  const { isLoggedInAuthor } = useSelector(
    (state) => state.utilities
  );
  const bookViewUrl = (() => {
    if (isLoggedInAuthor)
      return `${ROUTE_PATHS.authorsBookViewer}/${book?.id}`;
    if (isEditor)
      return `${ROUTE_PATHS.editorBookViewer}/${book?.id}`;
    if (isProgram)
      return `${ROUTE_PATHS.programBookProfile}/${book?.id}`;
    // return `${ROUTE_PATHS.programsBookViewer}/${book?.id}`;
    else return `${ROUTE_PATHS["bookProfile"]}/${book?.id}`;
  })();
  const BookWithNameAndAuthor = () => (
    <div className="book_container" title={book?.title}>
      <BookCover src={book.cover_photo} />
      <div
        onMouseEnter={() => selectBook()}
        onMouseLeave={() => clearSelection()}
        onTouchStart={() => {
          selectBook();
        }}
        style={wrapperStyle}
        className="white-wrapper"
        ref={wrapperRef}
      >
        <BooksNameAndAuthor book={book} isSelected={isSelected} />
        {isSelected && (
          <>
            {/* <span>{book?.brief}</span> */}
            <div className="d-flex justify-content-between card_btns flex-wrap gap-2">
              <Button
                text={
                  isLoggedInAuthor || isProgram || isEditor
                    ? "Preview"
                    : getAccess
                }
                isLink={true}
                slug={bookViewUrl}
              />
              {isEditor && (
                <Button
                  text={"Show Notes"}
                  slug={`${ROUTE_PATHS.editorNotes}/${book?.id}`}
                  isLink={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );

  return BookWithNameAndAuthor();
};

ClickableBookCover.propTypes = {
  isSelected: PropTypes.bool,
  index: PropTypes.number,
  clearSelection: PropTypes.func
};

export default ClickableBookCover;
