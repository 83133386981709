import React from "react";
import propTypes from "prop-types";
function DeleteIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9375 23C3.21563 23 2.59766 22.7498 2.08359 22.2493C1.56953 21.7488 1.3125 21.1472 1.3125 20.4444V3.83333H0V1.27778H6.5625V0H14.4375V1.27778H21V3.83333H19.6875V20.4444C19.6875 21.1472 19.4305 21.7488 18.9164 22.2493C18.4023 22.7498 17.7844 23 17.0625 23H3.9375ZM17.0625 3.83333H3.9375V20.4444H17.0625V3.83333ZM6.5625 17.8889H9.1875V6.38889H6.5625V17.8889ZM11.8125 17.8889H14.4375V6.38889H11.8125V17.8889Z"
        fill="#9F9C9C"
      />
    </svg>
  );
}
DeleteIcon.propTypes = {
  onClick: propTypes.func
};

export default DeleteIcon;
