import moment from "moment";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BookCover from "../../../components/BookCover";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import {
  axiosInstance,
  customer_portal
} from "../../../network/apis";
import {
  getBookQuizzes,
  getDirectorSingleBookAction,
  getSingleBookAction,
  setSingleBookAction
} from "../../../store/Books/actions";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import BookSubscriptionModal from "../BookSubscribtion/BooksSubscribtionModal/BookSubscribtionModal";
import styles from "./ProfileData.module.scss";
import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";

export default function BookData() {
  let params = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const { messages } = useIntl();
  const releaseFormat = "D MMM YYYY";
  const login = useSelector((state) => state.auth.login);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscribed, setSubscribed] = useState(null);
  const {
    buttons: { preview, enroll },
    shared: { by },
    books: { brief, sample, frontMatter, pages, quizzes, hours },
    errors: { subscriptionError },
    userBookList: {
      buttonStart,
      buttonContinue,
      completed,
      viewCourseLectures
    }
  } = messages;

  const { singleBook, singleBookQuizzes } = useSelector(
    (state) => state.books
  );

  const currentSunscriptionLoader = useSelector(
    (state) => state.books.currentSunscriptionLoader
  );

  useEffect(() => {
    if (params?.id) {
      if (isProgram) {
        dispatch(getDirectorSingleBookAction(params.id));
      } else {
        dispatch(getSingleBookAction(params.id));
        setSubscribed(
          login?.institution_subscription?.id ||
            login?.is_beta ||
            singleBook?.is_subscribed
        );
      }
    }
  }, [params]);

  useEffect(() => {
    console.log(
      "test sub",
      login?.institution_subscription?.id,
      login?.is_beta,
      singleBook?.is_subscribed
    );

    if (singleBook?.id) {
      setSubscribed(
        Boolean(
          login?.institution_subscription?.id ||
            login?.is_beta ||
            singleBook?.is_subscribed
        )
      );
      dispatch(
        getBookQuizzes({
          book_id: singleBook?.id,
          page: -1,
          isProgram
        })
      );
    }
  }, [singleBook]);

  useEffect(() => {
    return () => {
      dispatch(setSingleBookAction(null));
    };
  }, []);

  const bookStatistics = [
    {
      title: pages,
      // value: singleBook?.book_progress?.page || 0
      value: singleBook?.number_of_pages
    },
    {
      title: hours,
      value: `${singleBook?.reading_hours}`
    },
    {
      title: quizzes,
      value: singleBookQuizzes.length
    }
  ];

  const taps = [
    {
      title: brief,
      content: singleBook?.brief
    },
    {
      title: sample,
      content: <Samples book={singleBook} />
    },
    params?.id == 430 && {
      title: frontMatter,
      content: <FrontMatterPdf />
    }
  ];

  const handleSubscriptionModal = () => {
    setSubscriptionModal(true);
  };
  return (
    <div className="book-data">
      <div className={styles["upper-details"]}>
        <div className={styles["title"]}>
          <h3>{singleBook?.title}</h3>
          <span>{`${by} ${singleBook?.author}
          ${
            singleBook?.release_date
              ? ` | ${moment(singleBook.release_date).format(
                  releaseFormat
                )}`
              : ""
          }`}</span>
        </div>
        <div className={styles["book-access"]}>
          <div className={styles["statistics"]}>
            {bookStatistics.map(({ title, value }) => {
              return (
                <div key={title} className={styles["statistic"]}>
                  <span>{value}</span>
                  <span>{title}</span>
                </div>
              );
            })}
          </div>
          <div className={styles["buttons"]}>
            <Button
              text={viewCourseLectures}
              isLink={true}
              className="btn-option"
              slug={
                isProgram
                  ? `${ROUTE_PATHS.programsBookAllVideos}/${singleBook?.id}`
                  : `${ROUTE_PATHS["bookAllVideos"]}/${singleBook?.id}`
              }
            />
            {!subscribed && (
              <Button
                text={preview}
                isLink={true}
                className="primary"
                slug={
                  isProgram
                    ? `${ROUTE_PATHS.programsBookViewer}/${singleBook?.id}`
                    : `${ROUTE_PATHS["bookViewer"]}/${singleBook?.id}`
                }
              />
            )}
            {subscribed && (
              <>
                {(singleBook?.book_progress?.is_survey_solved ||
                  singleBook?.book_progress?.status ===
                    "completed") && (
                  <Button
                    isLink={true}
                    text={completed}
                    slug={`${ROUTE_PATHS["bookViewer"]}/${singleBook?.id}`}
                  />
                )}
                {(singleBook?.book_progress?.status ===
                  "not_started" ||
                  (!singleBook?.book_progress &&
                    login?.institution_subscription?.id) ||
                  (!singleBook?.book_progress && login?.is_beta)) && (
                  <Button
                    text={buttonStart}
                    isLink={true}
                    slug={`${ROUTE_PATHS["bookViewer"]}/${singleBook?.id}`}
                  />
                )}
                {singleBook?.book_progress?.status ===
                  "in_progress" && (
                  <Button
                    text={buttonContinue}
                    isLink={true}
                    slug={`${ROUTE_PATHS["bookViewer"]}/${singleBook?.id}`}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles["lower-details"]}>
        <div className={styles["book-cover"]}>
          <BookCover src={singleBook?.cover_photo} />
        </div>
        {!subscribed && !isProgram && (
          <div className={styles["enrollButton"]}>
            <p>${singleBook?.price}</p>
            <Button
              text={enroll}
              isLink={true}
              onClick={handleSubscriptionModal}
            />
          </div>
        )}
        <div className={styles["breif-sample"]}>
          <Taps data={taps} />
        </div>
      </div>
      <Modal isOpen={subscriptionModal} header={false}>
        <BookSubscriptionModal
          singleBook={singleBook}
          setSubscriptionModal={setSubscriptionModal}
        />
      </Modal>
    </div>
  );
}
const FrontMatterPdf = () => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
  const pdfUrl =
    "/A Practical Approach to Liver Imaging Course_Front Matter.pdf";

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
        const container = document.getElementById("Container");
        if (!container) {
          console.error("Container element not found");
          return;
        }
        container.innerHTML = "";
        const scale = 1;
        for (
          let pageNumber = 1;
          pageNumber <= pdf.numPages;
          pageNumber++
        ) {
          const page = await pdf.getPage(pageNumber);
          console.log(`Page ${pageNumber} loaded`);
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          canvas.id = `pdfcanvas_${pageNumber}`;
          container.appendChild(canvas);
          const context = canvas.getContext("2d");

          // canvas.height = viewport.height;
          // canvas.width = viewport.width;

          var resolution = 2; // for example
          canvas.height = resolution * viewport.height; //actual size
          canvas.width = resolution * viewport.width;
          canvas.style.height = viewport.height + "px"; //showing size will be smaller size
          canvas.style.width = viewport.width + "px";

          const renderContext = {
            canvasContext: context,
            viewport,
            transform: [resolution, 0, 0, resolution, 0, 0] // force it bigger size
          };
          await page.render(renderContext).promise;
        }
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  return (
    <div
      className={` ${styles["thinScrollBar"]}`}
      id="Container"
      style={{ height: "35vh", overflow: "auto" }}
    ></div>
  );
};
const Taps = ({ data = [] }) => {
  return (
    <>
      <Tabs className={`${styles["taps-titles"]} mb-3`}>
        {data.map((item, i) => {
          const { title, content } = item;
          return (
            <Tab
              key={title}
              title={title}
              eventKey={title}
              className="p-2"
            >
              {content}
            </Tab>
          );
        })}
      </Tabs>
      {/* <div className={styles["content"]}>
        {data?.map((item) => item.content)}
      </div> */}
    </>
  );
};

Taps.propTypes = {
  data: PropTypes.array
};

const Samples = ({ book }) => {
  useEffect(() => {
    let isMounted = true;
    if (!book?.pdf) return;
    const loadSample = async () => {
      const url = book.pdf;
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        process.env.REACT_APP_PDF_WORKER;
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;

      if (!isMounted) {
        return;
      }
      // const generateAscendingArray = (pdfNumPages) =>
      //   [
      //     1,
      //     ...new Set(
      //       Array.from(
      //         { length: 3 },
      //         () => Math.floor(Math.random() * (pdfNumPages - 1)) + 2
      //       )
      //     )
      //   ].sort((a, b) => a - b);

      // const resultArray = generateAscendingArray(pdf.numPages);
      const resultArray = [3, 8, 11, 14, 16, 18];

      const renderPage = async (pageNumber) => {
        try {
          if (!isMounted) {
            return;
          }

          const page = await pdf.getPage(pageNumber);

          const scale = 0.2;
          const viewport = page.getViewport({
            scale: scale
          });

          const canvas = document.createElement("canvas");
          canvas.id = "pdfcanvas" + pageNumber;
          document.getElementById("pdfContainer").appendChild(canvas);

          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };

          await page.render(renderContext).promise;

          if (!isMounted) {
            return;
          }
        } catch (error) {
          // console.error(`Error rendering page ${pageNumber}:`, error);
        }
      };

      // Create an array of promises for rendering pages
      const renderingPromises = resultArray.map(renderPage);

      // Wait for all pages to be rendered
      await Promise.all(renderingPromises);
    };

    loadSample();

    return () => {
      isMounted = false;
    };
  }, [book?.pdf]);

  return (
    <div
      className={`${styles["img-samples"]} ${styles["thinScrollBar"]}`}
      id="pdfContainer"
    ></div>
  );
};

Samples.propTypes = {
  book: PropTypes.object
};
