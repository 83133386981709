import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { userDetails } from "../../../store/Auth/actions";
import RelatedBooks from "../RelatedBooks";
import BookData from "./BookData";
import styles from "./ProfileData.module.scss";

const BookProfile = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.auth.login);
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");

  useEffect(() => {
    if (login?.auth_token && !isProgram) {
      dispatch(userDetails());
    }
  }, []);
  return (
    <Container className="mb-5">
      <Row className={styles["container"]}>
        <BookData />
        <RelatedBooks />
      </Row>
    </Container>
  );
};

export default BookProfile;
