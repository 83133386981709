import {
  author_portal,
  axiosInstance,
  customer_portal,
  editor_portal,
  program_director_portal
} from "./";

const getBooks = async (params) => {
  return await axiosInstance.get(`${customer_portal}/books`, {
    params
  });
};

const getUserProgram = async ({ userId }) => {
  return await axiosInstance.get(
    `${customer_portal}/programs/user_programs/${userId}`
  );
};

const getAuthorBooks = async (params) => {
  return await axiosInstance.get(`${author_portal}/books`, {
    params
  });
};
const getEditorBooks = async (params) => {
  return await axiosInstance.get(`${author_portal}/books`, {
    params
  });
};

const getRelatedBooks = async ({ id, params }) => {
  return await axiosInstance.get(
    `${customer_portal}/books/${id}/related_books`,
    {
      params
    }
  );
};
const getAuthorRelatedBooks = async ({ id, params }) => {
  return await axiosInstance.get(
    `${author_portal}/books/${id}/related_books`,
    {
      params
    }
  );
};

const getSingleBook = async (id) =>
  await axiosInstance.get(`${customer_portal}/books/${id}`);
const getAuthorSingleBook = async (id) =>
  await axiosInstance.get(`${author_portal}/books/${id}`);
const getEditorSingleBook = async (id) =>
  await axiosInstance.get(`${editor_portal}/books/${id}`);
const getDirectorSingleBook = async (id) =>
  await axiosInstance.get(`${program_director_portal}/books/${id}`);
const getAuthorAssignedBook = async () =>
  await axiosInstance.get(`${author_portal}/books/assigned_books`);
const getEditorAssignedBook = async () =>
  await axiosInstance.get(`${editor_portal}/books/assigned_books`);

const getSingeBookSupplementaries = async (params) =>
  await axiosInstance.get(
    `${customer_portal}/book_supplementary_materials`,
    { params }
  );
const getAuthorSingleBookSupplementaries = async (params) =>
  await axiosInstance.get(
    `${author_portal}/book_supplementary_materials`,
    { params }
  );
const getDirectorSingleBookSupplementaries = async (params) =>
  await axiosInstance.get(
    `${program_director_portal}/book_supplementary_materials`,
    { params }
  );

const unsubscribeBook = async ({ id, reason }) =>
  await axiosInstance.patch(
    `${customer_portal}/book_subscriptions/${id}`,
    {
      cancellation_reason: reason
    }
  );

const getSubscribedBooks = async (payload) =>
  await axiosInstance.get(`${customer_portal}/subscribed_books`, {
    params: { ...payload }
  });

const updateBookProgress = async ({ id, page, notesProgress }) => {
  if (notesProgress) {
    return await axiosInstance.patch(
      `${customer_portal}/books/update_current_progress?book_id=${id}&page=${page}`,
      {
        highlights_and_notes: { notesProgress }
      }
    );
  } else {
    return await axiosInstance.patch(
      `${customer_portal}/books/update_current_progress?book_id=${id}&page=${page}`
    );
  }
};

const updateBookSupplementaryProgress = async ({
  book_id,
  supplementary_material_id,
  watched
}) => {
  if (supplementary_material_id) {
    return await axiosInstance.patch(
      `${customer_portal}/books/update_supplementary_progress`,
      {
        book_id,
        supplementary_material_id,
        watched
      }
    );
  }
};

const getBookSupplementaryProgress = async ({ book_id }) => {
  return await axiosInstance.get(
    `books/list_supplementary_progress?book_id=${book_id}`
  );
};

const getBookQuizzes = async (payload) =>
  await axiosInstance.get(`${customer_portal}/book_quizzes`, {
    params: payload
  });
const getBookQuizzesForAuthors = async (payload) =>
  await axiosInstance.get(`${author_portal}/book_quizzes`, {
    params: payload
  });
const getBookQuizzesForDirectors = async (payload) =>
  await axiosInstance.get(`${program_director_portal}/book_quizzes`, {
    params: payload
  });
const getCertainQuiz = async (payload) =>
  await axiosInstance.get(
    `${customer_portal}/quizzes/${payload.id}`,
    {
      params: {
        book_id: payload.bookId,
        book_page: payload.page
      }
    }
  );
const getCertainQuizForAuthors = async (payload) =>
  await axiosInstance.get(`${author_portal}/quizzes/${payload.id}`, {
    params: {
      book_id: payload.bookId,
      book_page: payload.page
    }
  });
const getCertainQuizForDirector = async (payload) =>
  await axiosInstance.get(
    `${program_director_portal}/quizzes/${payload.id}`,
    {
      params: {
        book_id: payload.bookId,
        book_page: payload.page
      }
    }
  );

const getBookTests = async (params) =>
  await axiosInstance.get(`${customer_portal}/tests`, {
    params
  });
const getBookTestsForAuthor = async (params) =>
  await axiosInstance.get(`${author_portal}/tests`, {
    params
  });
const getBookTestsForDirector = async (params) =>
  await axiosInstance.get(`${program_director_portal}/tests`, {
    params
  });
const getBookSingleTest = async (payload) =>
  await axiosInstance.get(`${customer_portal}/tests/${payload.id}`);
const getBookSingleTestForAuthor = async (payload) =>
  await axiosInstance.get(`${author_portal}/tests/${payload.id}`);
const getBookSingleTestForDirector = async (payload) =>
  await axiosInstance.get(
    `${program_director_portal}/tests/${payload.id}`
  );

const answerBookSingleTest = async (payload) =>
  await axiosInstance.post(
    `${customer_portal}/test_answers/`,
    payload
  );

const getCertificateList = async (payload) =>
  await axiosInstance.get(`${customer_portal}/certificates`, {
    params: payload
  });
const setPromocode = async (payload) =>
  await axiosInstance.get(
    `${customer_portal}/promo_codes/request_discounted_price`,
    {
      params: payload
    }
  );
const setFreeDiscountPromocode = async (payload) =>
  await axiosInstance.post(
    `${customer_portal}/book_subscriptions/create_free_subscription`,

    payload
  );
const getInstitutionBooksList = async (payload) =>
  await axiosInstance.get(
    `${customer_portal}/books/list_institution_books`,
    {
      params: payload
    }
  );
const getEditorNotesByBook = async (payload) =>
  await axiosInstance.get(
    `${editor_portal}/annotations?book_id=${payload.bookId}&IsNote=${payload.isNote}`
  );
const getDirectorNotesByBook = async (payload) =>
  await axiosInstance.get(
    `${program_director_portal}/annotations?book_id=${payload.bookId}&IsNote=${payload.isNote}`
  );

export {
  getBooks,
  getUserProgram,
  getRelatedBooks,
  getSingleBook,
  getSingeBookSupplementaries,
  getAuthorSingleBookSupplementaries,
  getDirectorSingleBookSupplementaries,
  getSubscribedBooks,
  unsubscribeBook,
  updateBookProgress,
  updateBookSupplementaryProgress,
  getBookSupplementaryProgress,
  getBookQuizzes,
  getBookQuizzesForAuthors,
  getBookQuizzesForDirectors,
  getCertainQuiz,
  getCertainQuizForAuthors,
  getCertainQuizForDirector,
  getBookTests,
  getBookSingleTestForAuthor,
  getBookTestsForAuthor,
  getBookTestsForDirector,
  getBookSingleTest,
  answerBookSingleTest,
  getCertificateList,
  setPromocode,
  getInstitutionBooksList,
  setFreeDiscountPromocode,
  getAuthorBooks,
  getAuthorRelatedBooks,
  getAuthorSingleBook,
  getAuthorAssignedBook,
  getEditorAssignedBook,
  getEditorBooks,
  getEditorNotesByBook,
  getEditorSingleBook,
  getDirectorSingleBook,
  getDirectorNotesByBook,
  getBookSingleTestForDirector
};
