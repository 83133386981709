import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import FinalTest from ".";
import {
  author_portal,
  customer_portal,
  editor_portal,
  program_director_portal
} from "../../network/apis";
import { getFinalTestRequest } from "../../store/FinalTest/actions";
import {
  SET_FINAL_TEST_MODE,
  SET_FINAL_TEST_WRAPPER_MODE
} from "../../store/Utilities/types";
import {
  FinalTestMode,
  FinalTestWrapperViewMode
} from "../../utils/Constants";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import ViewScoreFinalTest from "./ViewScoreFinalTest";

function FinalTestWraper({ bookID, isModal }) {
  const { finalTestWrapperViewMode, modeFinalTest } = useSelector(
    (state) => state.utilities
  );
  const finalTest = useSelector(
    (state) => state.FinalTestReducer.finalTest
  );
  const { mode, book_id, test_view_mode } = useParams();
  const history = useHistory();
  // mode  ==> test/score
  // test_view_mode ==?
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const isAuthor = pathname.includes("author");
  const isEditor = pathname.includes("editor");

  const firstTimeAnswering =
    !finalTest?.user_answers?.answers?.length;
  const dispatch = useDispatch();
  let bookId = bookID || book_id;
  let wrapperMode = mode || finalTestWrapperViewMode;
  let testViewMode = test_view_mode || modeFinalTest;
  useEffect(() => {
    if (firstTimeAnswering)
      dispatch({
        type: SET_FINAL_TEST_MODE,
        payload: FinalTestMode.Take
      });
  }, []);

  useEffect(() => {
    let portal = customer_portal;
    if (isProgram) {
      portal = program_director_portal;
    }
    if (isAuthor) {
      portal = author_portal;
    }
    if (isEditor) {
      portal = editor_portal;
    }
    dispatch(getFinalTestRequest({ portal, book_id: bookId }));
  }, [testViewMode]);

  const viewFinalTest = (testMode) => {
    //Take or View Final Test
    if (isModal) {
      dispatch({
        type: SET_FINAL_TEST_WRAPPER_MODE,
        payload: FinalTestWrapperViewMode.ViewTest
      });
    } else {
      const route = `${ROUTE_PATHS.FinalTest}/${FinalTestWrapperViewMode.ViewTest}/${book_id}/${testMode}`;
      history.push(route);
    }
  };

  return (
    <>
      {wrapperMode === FinalTestWrapperViewMode.ViewScore &&
      !firstTimeAnswering ? (
        <ViewScoreFinalTest
          book_id={bookId}
          finalTestAnswers={finalTest}
          isModal={isModal}
          viewFinalTestFunc={viewFinalTest}
        />
      ) : (
        <FinalTest
          book_id={bookId}
          test_view_mode={testViewMode}
          finalTest={finalTest}
          isModal={isModal}
          isProgram={isProgram}
        />
      )}
    </>
  );
}
FinalTestWraper.propTypes = {
  bookID: PropTypes.any,
  isModal: PropTypes.bool.isRequired
};

export default FinalTestWraper;
