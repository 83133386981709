import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/studentsList";
import { getStudentsListResponse } from "./action";

export function* getStudentsListSaga({ payload }) {
  try {
    const response = yield call(apis.getStudentsList, payload);

    yield put(
      getStudentsListResponse({
        resList: response.data.students_overview,
        searchKeyword: response.data.searchKeyword || ""
      })
    );
  } catch (err) {
    console.error(err);
  }
}
