import {
  axiosInstance,
  customer_portal,
  program_director_portal
} from ".";

const setNewTopicProgram = async (programId, topic) =>
  await axiosInstance.post(
    `${program_director_portal}/programs/${programId}/topics`,
    { topic }
  );
const currentProgram = async (params) =>
  await axiosInstance.get(
    `${program_director_portal}/programs/current`,
    {
      params
    }
  );
const getTopics = async (programId) =>
  await axiosInstance.get(
    `${program_director_portal}/programs/${programId}/topics`
  );
const getCusromerTopics = async (programId) =>
  await axiosInstance.get(
    `${customer_portal}/programs/${programId}/topics`
  );
const getSingleTopic = async (programId, topicId) =>
  await axiosInstance.get(
    `${program_director_portal}/programs/${programId}/topics/${topicId}`
  );

const getSingleTopicStudent = async (programId, topicId) =>
  await axiosInstance.get(
    `${customer_portal}/programs/${programId}/topics/${topicId}`
  );

const deleteSingleTopic = async (programId, topicId) =>
  await axiosInstance.delete(
    `${program_director_portal}/programs/${programId}/topics/${topicId}`
  );
const editOldTopic = async (programId, topicId, data) =>
  await axiosInstance.put(
    `${program_director_portal}/programs/${programId}/topics/${topicId}`,
    data
  );
export {
  setNewTopicProgram,
  getTopics,
  currentProgram,
  getSingleTopic,
  deleteSingleTopic,
  getCusromerTopics,
  getSingleTopicStudent,
  editOldTopic
};
