import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch, useSelector } from "react-redux";

import { axiosInstance, customer_portal } from "../../network/apis";
import history from "../../routes/History";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import {
  getSubscribedBooksRequest,
  setCurrentSubscriptionLoader
} from "../../store/Books/actions";

const PayPalButtonDiscount = () => {
  const dispatch = useDispatch();

  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  let promocodeDiscountSaved = JSON.parse(
    localStorage.getItem("promocodeDiscountSaved")
  );
  return (
    <>
      <PayPalButton
        options={{
          vault: true,
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: "USD"
        }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: "P-96L15422HN589982WM4EBB6Y"
            // plan_id: subscribeBookSelected.paypal_plan_id,
            // plan: {
            //   billing_cycles: [
            //     {
            //       tenure_type: "REGULAR",
            //       sequence: 1,
            //       total_cycles: 0,
            //       pricing_scheme: {
            //         fixed_price: {
            //           value:
            //             promocodeDiscount ||
            //             promocodeDiscount === 0 ||
            //             promocodeDiscountSaved.promocodeDiscount,
            //           currency_code: "USD"
            //         }
            //       }
            //     }
            //   ]
            // }
          });
        }}
        onApprove={(data, actions) => {
          return actions.subscription
            .get()
            .then(async function () {
              try {
                let resposone = await axiosInstance.post(
                  `${customer_portal}/book_subscriptions`,
                  {
                    payment_gateway: "paypal",
                    book_id: subscribeBookSelected.id,
                    subscription_id: data.subscriptionID
                  }
                );
                localStorage.setItem(
                  "subscription_id",
                  resposone?.data?.book_subcription?.id
                );
              } catch (err) {
                console.error(err);
              }
            })
            .then(() => {
              // This is the loading key

              dispatch(
                setCurrentSubscriptionLoader({
                  id: subscribeBookSelected.id
                })
              );
            })
            .then(() => {
              dispatch(getSubscribedBooksRequest({}));
              sessionStorage.removeItem("bookToBuy");
              history.push(
                `${ROUTE_PATHS.bookProfile}/${subscribeBookSelected.id}`
              );
            });
        }}
        style={{
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "pay"
        }}
      />
    </>
  );
};

export default PayPalButtonDiscount;
