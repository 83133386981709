import React from "react";

function FolderIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 25.8333H22.5V24.1666H10.8333V25.8333ZM10.8333 19.1666H29.1667V17.4999H10.8333V19.1666ZM7.69333 31.6666C6.92556 31.6666 6.285 31.4099 5.77167 30.8966C5.25833 30.3833 5.00111 29.7421 5 28.9733V11.0266C5 10.2588 5.25722 9.61825 5.77167 9.10492C6.28611 8.59158 6.92611 8.33436 7.69167 8.33325H15.9933L19.3267 11.6666H32.3083C33.075 11.6666 33.7156 11.9238 34.23 12.4383C34.7444 12.9527 35.0011 13.5933 35 14.3599V28.9749C35 29.7416 34.7433 30.3821 34.23 30.8966C33.7167 31.411 33.0761 31.6677 32.3083 31.6666H7.69333ZM7.69333 29.9999H32.3083C32.6072 29.9999 32.8528 29.9038 33.045 29.7116C33.2372 29.5194 33.3333 29.2738 33.3333 28.9749V14.3583C33.3333 14.0594 33.2372 13.8138 33.045 13.6216C32.8528 13.4294 32.6072 13.3333 32.3083 13.3333H18.6583L15.325 9.99992H7.69167C7.39278 9.99992 7.14722 10.096 6.955 10.2883C6.76278 10.4805 6.66667 10.7266 6.66667 11.0266V28.9749C6.66667 29.2738 6.76278 29.5194 6.955 29.7116C7.14722 29.9038 7.39333 29.9999 7.69333 29.9999Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}

export default FolderIcon;
