export const SET_OPEN_MATERIAL_MODAL = "SET_OPEN_MATERIAL_MODAL";
export const SET_OPEN_TEST_MODAL = "SET_OPEN_TEST_MODAL";
export const SET_OPEN_QUIZ_MODAL = "SET_OPEN_QUIZ_MODAL";
export const SET_MATERIAL_MODAL_DATA = "SET_MATERIAL_MODAL_DATA";
export const SET_TEST_MODAL_DATA = "SET_TEST_MODAL_DATA";
export const SET_QUIZE_MODAL_DATA = "SET_QUIZE_MODAL_DATA";
export const SET_IS_LOGGED_IN_AUTHOR = "SET_IS_LOGGED_IN_AUTHOR";
export const SET_OPEN_SURVEY = "SET_OPEN_SURVEY";
export const SET_OPEN_FINAL_TEST_MODAL = "SET_OPEN_FINAL_TEST_MODAL";
export const SET_FINAL_TEST_MODE = "SET_FINAL_TEST_MODE";
export const SET_FINAL_TEST_WRAPPER_MODE =
  "SET_FINAL_TEST_WRAPPER_MODE";
