export const BOOKS_LIST_TYPES = {
  featured: "featured",
  related: "related"
};

export const SORT_BOOKS_OPTIONS = (messages) => {
  const { priceHigh, newsetToOldest, priceLow } = messages;
  return [
    { id: "price_high", value: priceHigh, label: priceHigh },
    { id: "price_low", value: priceLow, label: priceLow },
    { id: "date", value: newsetToOldest, label: newsetToOldest }
  ];
};

export const subscriptionStatus = {
  active: "active",
  cancelled: "cancelled"
};

export const PDF_VIEWER_WORKER_URL =
  "/pdfjs-4.0.189-dist/build/pdf.worker.min.js";

export const FinalTestMode = {
  Take: "Take",
  View: "View"
};
export const FinalTestWrapperViewMode = {
  ViewScore: "ViewScore",
  ViewTest: "ViewTest"
};
