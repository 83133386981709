import { axiosInstance, program_director_portal } from ".";

const getStudentsList = async (params) =>
  await axiosInstance.get(
    `${program_director_portal}/students/students_overview`,
    {
      params
    }
  );

export { getStudentsList };
