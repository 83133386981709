import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SwapVerticalIcon from "../../../assets/svgs/SwapVerticalIcon";
import CategoriesListIconMenu from "../../../components/CategoriesMenu";
import CustomSelect from "../../../components/Select";
import {
  getAssignedBooksAction,
  getBooksAction,
  getEditoeAssignedBooksAction,
  setBooksFilters
} from "../../../store/Books/actions";
import { SORT_BOOKS_OPTIONS } from "../../../utils/Constants";
import { getParams } from "./BooksListingHelpers";

const Filters = ({ items }) => {
  const dispatch = useDispatch();
  const { listType } = useParams();
  const { messages } = useIntl();
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const categories = useSelector((state) => state.categories.list);
  const booksFilters = useSelector((state) => state.books.filters);
  const loginData = useSelector((state) => state.auth.login);
  // console.log("loginData", loginData);
  const { auth_token } = loginData || {};
  const params = getParams(listType, items);
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isEditor = pathname.includes("editor");
  useEffect(() => {
    const payload = {
      ...params,
      ...booksFilters,
      isAuthor,
      isEditor
    };
    if (auth_token && isAuthor) {
      dispatch(getAssignedBooksAction(payload));
    }
    if (auth_token && isEditor) {
      dispatch(getEditoeAssignedBooksAction(payload));
    }
    dispatch(getBooksAction(payload));
    // call get books on auth change as well
  }, [booksFilters, auth_token]);

  const [selectWidth, setselectWidth] = useState("40px");
  const handleSort = (id) => {
    if (id) setselectWidth("170px");
    dispatch(setBooksFilters({ ...booksFilters, sorting_term: id }));
  };

  const handleCategoriesFilter = (id) => {
    if (selectedCategoryId === id) {
      setSelectedCategoryId(null);
      dispatch(
        setBooksFilters({ ...booksFilters, category_id: null })
      );
    } else {
      if (typeof id === "number") {
        setSelectedCategoryId(id);
        const category = document?.querySelector(`#category_${id}`);
        if (category) category?.click();
        dispatch(
          setBooksFilters({ ...booksFilters, category_id: id })
        );
      }
    }
  };
  const customeSelectMenuStyle = {
    width: "230px",
    right: 0
  };
  const {
    books,
    shared: { sortBy }
  } = messages;

  return (
    <div className="d-flex align-items-center justify-content-end w-100 mt-3 mb-xs-40">
      <CategoriesListIconMenu
        categories={categories}
        selectedCategoryId={selectedCategoryId}
        setSelectedCategory={handleCategoriesFilter}
      />

      {/* <div className="overflow-hidden pe-4 w-100 home-slider">
        <Slider {...settings} focusOnSelect>
          {categories?.map((category) => (
            <div
              key={category.id}
              className={`${styles.chip} ${
                selectedCategoryId === category.id
                  ? styles.selectedChip
                  : ""
              }`}
              id={`category_${category.id}`}
              onClick={() => handleCategoriesFilter(category.id)}
            >
              <span className="cursor-pointer">{category.name}</span>
            </div>
          ))}
        </Slider>
      </div> */}
      <Formik initialValues={{ sort: null }}>
        {() => (
          <Form>
            <CustomSelect
              showSelectedValue={selectWidth === "170px"}
              options={SORT_BOOKS_OPTIONS(books)}
              // placeholder={sortBy}
              customStyle={customeSelectMenuStyle}
              name="sort"
              handleSelectChange={handleSort}
              icon={<SwapVerticalIcon />}
              width={selectWidth}
              isSearchable={true}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

Filters.propTypes = {
  items: PropTypes.number
};

Filters.defaultProps = {
  items: 12
};

export default Filters;
