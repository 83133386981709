import { Container, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./AddNewTopic.scss";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  editOldTopicRequest,
  getCurrentProgramRequest,
  getSingleTopicRequest,
  setTopicRequest
} from "../../store/Topics/actions";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { useHistory, useParams } from "react-router-dom";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import StudentsOrTopics from "../../components/StudentsOrTopics";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }],
    ["link", "image"],
    ["clean"]
  ]
};
function EditOrAddNewTopic() {
  const { messages } = useIntl();
  const [newTopicContent, setNewTopicContent] = useState("");
  const [newTopicTitle, setNewTopicTitle] = useState("");
  const quill = useRef();
  const history = useHistory();
  const { program_id, id, mode } = useParams();
  const dispatch = useDispatch();

  const oldTopic = useSelector((state) => state.topics.Topic);
  useEffect(() => {
    if (mode === "EditOldTopic") {
      dispatch(getSingleTopicRequest(program_id, id));
    }
  }, [program_id, id, dispatch, mode]);
  useEffect(() => {
    if (oldTopic) {
      setNewTopicTitle(oldTopic?.title || "");
      setNewTopicContent(oldTopic?.content || "");
    }
  }, [oldTopic]);

  const {
    topic: { cancel, save, addMessage }
  } = messages;
  const currentProgram = useSelector(
    (state) => state.topics.currentProgram
  );

  const { loading } = useSelector((state) => state.topics);

  useEffect(() => {
    dispatch(getCurrentProgramRequest());
    if (mode === "AddNewTopic") {
      setNewTopicTitle("");
      setNewTopicContent("");
    }
  }, []);

  const [inputError, setInputError] = useState(false);
  function handler() {
    if (newTopicContent !== "" && newTopicTitle !== "") {
      setInputError(false);
      if (mode === "AddNewTopic") {
        dispatch(
          setTopicRequest(currentProgram.id, {
            title: newTopicTitle,
            content: newTopicContent
          })
        );
        dispatch(
          showHideSnackbar({
            isOpen: true,
            type: "success",
            message: addMessage
          })
        );
      } else if (mode === "EditOldTopic") {
        dispatch(
          editOldTopicRequest(program_id, id, {
            topic: {
              title: newTopicTitle,
              content: newTopicContent
            }
          })
        );
      }
    } else {
      setInputError(true);
    }
  }
  function deleteContent() {
    setNewTopicContent("");
    setNewTopicTitle("");
    history.push(ROUTE_PATHS.Topics);
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "clean"
  ];

  return (
    <>
      <StudentsOrTopics />
      <Container sx={{ width: "80%" }}>
        <div className={styles.wrapper}>
          <div className="TopicNameStyle">
            <TextField
              error={inputError}
              id="standard-basic"
              style={{ fontStyle: "italic" }}
              variant="standard"
              placeholder="enter topic name"
              value={newTopicTitle}
              onChange={(e) => {
                if (e.target.value) setInputError(false);
                else setInputError(true);
                setNewTopicTitle(e.target.value);
              }}
            />
          </div>
          <QuillEditor
            ref={(el) => (quill.current = el)}
            className={styles.editor}
            theme="snow"
            value={newTopicContent}
            formats={formats}
            modules={modules}
            onChange={(value) => setNewTopicContent(value)}
          />
          <div className="buttonsAddTopic">
            <button
              onClick={handler}
              disabled={loading}
              className="buttonSave"
            >
              {save}
            </button>
            <button onClick={deleteContent}>{cancel}</button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default EditOrAddNewTopic;
