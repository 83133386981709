import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { ThemeProvider } from "@mui/material/styles";
import RTLSwitcher from "../themes/RTLtheme";
import theme from "../themes/light";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { axiosInstance } from "../network/apis";
import { useSelector } from "react-redux";

function MuiProvider({ children }) {
  const { locale } = useIntl();
  const [dir, setDir] = useState("ltr");

  useEffect(() => {
    if (locale === "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.removeAttribute("dir");
    }

    setDir(locale == "ar" ? "rtl" : "ltr");
  }, [locale]);

  const themeProvider = theme({ dir });

  return (
    <RTLSwitcher lang={locale}>
      {/* <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          currency: "USD",
          intent: "capture",
          components: "card-fields,buttons,hosted-fields",
          "data-client-token": dataClientToken
        }}
      > */}
      <ThemeProvider theme={themeProvider}>{children}</ThemeProvider>
      {/* </PayPalScriptProvider> */}
    </RTLSwitcher>
  );
}

MuiProvider.propTypes = {
  children: PropTypes.any
};

export default MuiProvider;
