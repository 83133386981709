import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import "./TableTopics.scss";
import SingleRowTopic from "../SingleRowTopic";
import { useIntl } from "react-intl";
function TableTopics({ programId, AllTopics }) {
  const { messages } = useIntl();

  const {
    topic: {
      topiclist: { topicName, date }
    }
  } = messages;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: "40px"
        }}
      >
        <TableContainer
          sx={{
            width: "80%",
            boxShadow: "none",
            border: "none"
          }}
        >
          <Table
            sx={{
              borderCollapse: "collapse",
              border: "none"
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                >
                  {topicName}
                </TableCell>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                >
                  {date}
                </TableCell>
                <TableCell
                  sx={{ color: "#9F9C9C", borderBottom: "none" }}
                ></TableCell>
                <TableCell
                  sx={{ borderBottom: "none" }}
                  align="right"
                />
              </TableRow>
            </TableHead>

            <TableBody>
              {AllTopics?.map((topic) => (
                <SingleRowTopic
                  key={topic.id}
                  programId={programId}
                  topic={topic}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
TableTopics.propTypes = {
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  AllTopics: PropTypes.array.isRequired
};

export default TableTopics;
