import PropTypes from "prop-types";
import React from "react";
import userImage from "../../../assets/images/user-image.png";
import DeleteIconTopic from "../../../assets/svgs/DeleteIconTopic";
import "../topic.scss";
function SingleComment({ comment, triggerDeleteModal }) {
  return (
    <div
      key={comment?.id}
      style={{
        width: "100%",
        border: "1px solid  #7D7D7D",
        borderRadius: "10px",
        padding: "10px",
        color: "#8D8D8D",
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          width: "80%"
        }}
      >
        <div>
          <img src={userImage} width={20} />
        </div>
        <div>{comment.content}</div>
      </div>
      <div
        style={{
          scale: ".7",
          display: "flex",
          gap: "10px",
          alignItems: "center"
        }}
      >
        <div onClick={() => triggerDeleteModal(comment?.id)}>
          <DeleteIconTopic />
        </div>
      </div>
    </div>
  );
}

SingleComment.propTypes = {
  comment: PropTypes.shape({
    content: PropTypes.string.isRequired,
    id: PropTypes.number
  }).isRequired,
  triggerDeleteModal: PropTypes.func
};
export default React.memo(SingleComment);
