import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography } from "@mui/material";
import {
  getSingleBookSupplementariesAction,
  updateBookSupplementaryProgress,
  getBookSupplementaryProgress
} from "../../store/Books/actions";
import DicomViewer from "../../components/DicomViewer";
import styles from "./SupplementaryDisplay.module.scss";
import { ImgDisplay } from "../../hooks/ImgDisplay";
import { KEEP_LINE_SPACES } from "../../utils/Patterns";
import PlayIcon from "../../assets/svgs/play";
import { Container, Row } from "react-bootstrap";

function SupplementaryDisplay() {
  const { id, bookId } = useParams();
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const isAuthor = pathname.includes("author");
  const dispatch = useDispatch();
  const { singleBookSupplementaries, SupplementariesProgress } =
    useSelector((state) => state.books);

  // Use state to persist `singleSupplementary` across renders
  const [singleSupplementary, setSingleSupplementary] = useState(
    () => {
      const localData = JSON.parse(
        localStorage.getItem("supplementary")
      );
      return localData || null;
    }
  );
  console.log("singleBookSupplementaries", singleBookSupplementaries);

  useEffect(() => {
    // Check if `singleBookSupplementaries` is defined and is an array
    if (Array.isArray(singleBookSupplementaries)) {
      const allSubsForBook = singleBookSupplementaries
        .map((sub) => sub?.supplementary_materials || []) // Fallback to an empty array if `supplementary_materials` is undefined
        .flat();
      console.log("allSubsForBook", allSubsForBook);

      // Find the supplementary item that matches the `id` parameter
      const foundSupplementary = allSubsForBook.find(
        (sub) => +sub?.id === +id
      );

      if (foundSupplementary) {
        setSingleSupplementary(foundSupplementary);
      } else if (!singleSupplementary) {
        // Fallback to localStorage if no match is found
        const localData = JSON.parse(
          localStorage.getItem("supplementary")
        );
        setSingleSupplementary(localData);
      }
    }
  }, [id, singleBookSupplementaries]);

  // Fetch supplementary data only once on mount or when `bookId` changes
  useEffect(() => {
    if (bookId != null) {
      dispatch(
        getSingleBookSupplementariesAction({
          book_id: bookId,
          isProgram,
          isAuthor
        })
      );
    }
  }, [bookId]);

  useEffect(() => {
    if (
      singleSupplementary?.material_type === "video" &&
      !isProgram &&
      !isAuthor
    ) {
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  }, [singleSupplementary, bookId]);

  const handleProgress = (state) => {
    if (
      !SupplementariesProgress[
        +singleSupplementary.supplementary_material_id
      ] &&
      state.played >= 0.9 &&
      !isProgram &&
      !isAuthor
    ) {
      dispatch(
        updateBookSupplementaryProgress({
          book_id: +bookId,
          supplementary_material_id:
            +singleSupplementary.supplementary_material_id,
          watched: true
        })
      );
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  };

  const renderPreview = (videoWithControls) => {
    switch (singleSupplementary?.material_type) {
      case "video":
        return (
          <div className={styles.video}>
            <ReactPlayer
              className={styles.preview}
              url={singleSupplementary?.url?.[0]}
              width="100%"
              height="100%"
              controls={videoWithControls}
              config={{
                file: { attributes: { controlsList: "nodownload" } }
              }}
              onProgress={handleProgress}
            />
            {!videoWithControls && (
              <div className={styles.videoWrapper}>
                <PlayIcon width="20px" height="20px" />
              </div>
            )}
          </div>
        );
      case "image":
        return (
          <div className={styles.item}>
            <ImgDisplay
              src={singleSupplementary?.url?.[0]}
              className={styles.preview}
            />
          </div>
        );
      case "DICOM":
        return (
          <div className={styles.item}>
            <DicomViewer
              imageIds={singleSupplementary?.url || []}
              className={styles.preview}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (!singleSupplementary) {
    return <>This page should be visited via the book viewer</>;
  }

  return (
    <div style={{ minHeight: "400px", height: "300px" }}>
      <Container>
        <Row>
          {renderPreview(true)}
          <Typography
            sx={{
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "17px"
            }}
          >
            {singleSupplementary.material_title}
          </Typography>
          {singleSupplementary?.diagnosis && (
            <p
              className={`lh-lg ${styles.description} text-gray`}
              dangerouslySetInnerHTML={{
                __html: singleSupplementary?.diagnosis.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
          )}
          <p
            className={`lh-lg ${styles.description}`}
            dangerouslySetInnerHTML={{
              __html: singleSupplementary.description.replace(
                KEEP_LINE_SPACES,
                "<br />"
              )
            }}
          ></p>
        </Row>
      </Container>
    </div>
  );
}

export default SupplementaryDisplay;
