import React from "react";
import { useDispatch } from "react-redux";
import { SET_OPEN_FINAL_TEST_MODAL } from "../../../store/Utilities/types";
import MaterialPreviewStyles from "../MaterialPreview.module.scss";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useIntl } from "react-intl";
function FinalTestPreview() {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    FinalTest: { FinalTest }
  } = messages;
  const setOpenFinalTest = (open) => {
    dispatch({
      type: SET_OPEN_FINAL_TEST_MODAL,
      payload: open
    });
  };

  return (
    <div className={MaterialPreviewStyles.wrapper}>
      <div className={MaterialPreviewStyles.quizCover}>
        <button
          className={`${MaterialPreviewStyles.emptyPreview} d-flex w-100 fsize-20`}
          onClick={() => {
            setOpenFinalTest(true);
          }}
        >
          <AssignmentIcon sx={{ marginRight: "10px" }} />
          <h6 className="text-start my-auto">{FinalTest}</h6>
        </button>
      </div>
    </div>
  );
}

export default FinalTestPreview;
