import React from "react";
import propTypes from "prop-types";

function ArrowRightCircle({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="40"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickability
    >
      <path
        d="M24 32L32 24M32 24L24 16M32 24H16M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z"
        stroke="#9F9C9C"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ArrowRightCircle.propTypes = {
  onClick: propTypes.func // Specify that onClick should be a function
};

export default ArrowRightCircle;
