import { axiosInstance, customer_portal } from ".";

const getFinalTest = async ({ portal, book_id }) =>
  await axiosInstance.get(`${portal}/final_tests?book_id=${book_id}`);

const setNewAnswerCustromerFinalTest = async (book_id, answers) => {
  const response = await axiosInstance.post(
    `${customer_portal}/final_tests/submit_final_test?book_id=${book_id}`,
    { answers }
  );

  return response;
};

export { setNewAnswerCustromerFinalTest, getFinalTest };
