import * as types from "./types";

export const getFinalTestRequest = (payload) => {
  return {
    type: types.GET_FINAL_TEST_REQUEST,
    payload
  };
};

export const getFinalTestResponse = (data) => ({
  type: types.GET_FINAL_TEST_RESPONSE,
  payload: data
});

export const setNewFinalTestAnswerRequest = ({
  answers,
  book_id
}) => {
  return {
    type: types.SET_NEW_FINAL_TEST_ANSWER_REQUEST,
    payload: { answers, book_id }
  };
};

export const setNewFinalTestAnswerResponse = (data) => ({
  type: types.SET_NEW_FINAL_TEST_ANSWER__RESPONSE,
  payload: data
});
