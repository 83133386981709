import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Formik } from "formik";

import { ROUTE_PATHS } from "../../utils/PathsNames";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Footer from "../Footer";
import {
  setPromocodeRequest,
  setPromocodeResponse,
  setPromocodeNameResponse,
  setFreeDiscountPromocodeRequest
} from "../../store/Checkout/actions";
import history from "../../routes/History";
import styles from "./Promocode.module.scss";
import PayPalButtons from "../../components/CheckoutForm/PayPalButtons";
import { showHideSnackbar } from "../../store/Snackbar/actions";
import { axiosInstance, customer_portal } from "../../network/apis";
import { showHideLoader } from "../../store/Loader/actions";

const Promocode = () => {
  const { messages } = useIntl();
  let params = useParams();
  const dispatch = useDispatch();
  const defaultPromoResult = {
    success: false,
    discounted_price: -1,
    discount_price: 0,
    promo_code: ""
  };
  const [appliedPromoData, setAppliedPromoData] = useState(
    defaultPromoResult
  );
  const [disabledInput, setdisabledInput] = useState(false);
  const {
    checkoutForm: {
      checkout,
      completePayment,
      promocodeStatement,
      bookTitle,
      apply,
      promocode,
      price,
      removePromo
    }
  } = messages;

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
    return () => {
      dispatch(setPromocodeResponse(null));
      dispatch(setPromocodeNameResponse(null));
    };
  }, []);
  const subscribeBookSelected = useSelector(
    (state) => state.books.subscribeBookSelected
  );
  const promocodeDiscount = useSelector(
    (state) => state.checkout.promocode
  );
  const promocodeName = useSelector(
    (state) => state.checkout.promocodeName
  );
  let promocodeDiscountSaved = JSON.parse(
    localStorage.getItem("promocodeDiscountSaved")
  );
  useEffect(() => {
    if (promocodeDiscountSaved) {
      localStorage.removeItem("promocodeDiscountSaved");
    }
  }, []);

  const handleSubmit = async (values) => {
    getDiscountedPrice(values.promocode);
    // try {
    //   await dispatch(
    //     setPromocodeRequest({
    //       book_id: params?.id,
    //       name: values.promocode
    //     })
    //   );
    // } catch (err) {
    //   console.log(err);
    // }
  };
  const removePromocode = () => {
    if (
      promocodeDiscount ||
      promocodeDiscountSaved ||
      promocodeName
    ) {
      dispatch(setPromocodeResponse(null));
      dispatch(setPromocodeNameResponse(null));
      localStorage.removeItem("promocodeDiscountSaved");
    }
  };
  function getPromoCodePlanId(promoName) {
    return subscribeBookSelected?.promo_plans.find(
      (pl) => pl.promo_code.name === promoName
    )?.plan_id;
  }

  const getDiscountedPrice = async (promoName) => {
    try {
      dispatch(showHideLoader(true));
      const { data } = await axiosInstance.get(
        `${customer_portal}/promo_codes/request_discounted_price?name=${promoName}&book_id=${params?.id}`
      );
      console.log("promo data: ", data);
      setAppliedPromoData(data);
      dispatch(showHideLoader(false));
    } catch (error) {
      setAppliedPromoData(defaultPromoResult);
      console.log(error);
      throw error;
    }
  };

  const createFreeSubscription = async () => {
    dispatch(showHideLoader(true));
    const { data } = await axiosInstance.post(
      `${customer_portal}/book_subscriptions/create_free_subscription`,
      {
        promocode_name: appliedPromoData.promo_code,
        book_id: params?.id
      }
    );
    dispatch(showHideLoader(false));
    sessionStorage.removeItem("bookToBuy");
    history.push(`${ROUTE_PATHS.bookProfile}/${params?.id}`);
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={6} xs={12} className="mx-auto mb-5">
            <div className={styles.container}>
              <Col xs="12">
                <h3 className="mb-5">{checkout}</h3>
                <p className=" font-R fsize-14 mb-3">
                  {promocodeStatement}
                </p>
              </Col>
              <Col xs="12 ">
                <Formik
                  initialValues={{
                    promocode:
                      promocodeDiscountSaved || promocodeName
                        ? promocodeName
                        : ""
                  }}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ submitForm, isValid, values }) => {
                    console.log(values);

                    return (
                      <section
                        className={
                          disabledInput ||
                          promocodeDiscount ||
                          promocodeDiscount === 0
                            ? styles.disabledInput
                            : null + "d-flex align-items-start"
                        }
                      >
                        <Form>
                          <Row>
                            <Col
                              className={`mx-auto ${styles.promocode}`}
                              sm={12}
                              md={12}
                            >
                              <div>
                                <Input
                                  name="promocode"
                                  label={promocode}
                                  isRequired={true}
                                  disabled={
                                    promocodeDiscount ||
                                    promocodeDiscount === 0
                                  }
                                />
                              </div>
                              <Button
                                className="primary_light_border"
                                text={apply}
                                type="submit"
                                disabled={!isValid}
                                onClick={(e) => {
                                  e.preventDefault();
                                  submitForm();
                                }}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </section>
                    );
                  }}
                </Formik>
              </Col>
              {(promocodeDiscount || promocodeDiscount === 0) && (
                <Col xs="12" className="cursor-pointer">
                  <a
                    onClick={() => {
                      removePromocode();
                    }}
                    className="text-secondary fsize-12"
                  >
                    {removePromo}
                  </a>
                </Col>
              )}
              <Col xs="12" className="mt-5">
                <div>
                  <p>
                    {bookTitle}{" "}
                    <span className="fsize-18 font-M">
                      {subscribeBookSelected?.title}
                    </span>
                  </p>
                  <div>
                    <p className="d-flex">
                      <span className="m-1">{price} </span>
                      <p className="m-1">
                        <span
                          className={
                            appliedPromoData.success
                              ? ` ${styles.promocodeApplied} text-primary`
                              : " text-primary"
                          }
                        >
                          ${subscribeBookSelected?.price}
                        </span>
                        <br />{" "}
                        {appliedPromoData.success && (
                          <span className="text-secondary">
                            {appliedPromoData.discounted_price}
                          </span>
                        )}
                      </p>
                    </p>
                  </div>
                </div>
              </Col>
              {appliedPromoData.discounted_price !== 0 ? (
                subscribeBookSelected?.paypal_plan_id && (
                  <PayPalButtons
                    planId={
                      getPromoCodePlanId(
                        appliedPromoData.promo_code
                      ) ?? subscribeBookSelected?.paypal_plan_id
                    }
                  />
                )
              ) : (
                <button
                  style={{
                    margin: "auto",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "7px",
                    color: "white",
                    backgroundColor: "#0E4DCE"
                  }}
                  onClick={createFreeSubscription}
                >
                  Subscribe For Free
                </button>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Promocode;
