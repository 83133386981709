import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { default as React, useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CloseButtonIcon from "../../../assets/svgs/close-button-icon";
import Group from "../../../assets/svgs/Group.svg";
import { boxStyle } from "../../../containers/Books/BookViewer/boxStyle";
import {
  author_portal,
  axiosInstance,
  customer_portal,
  program_director_portal
} from "../../../network/apis";
import {
  answerBookSingleTestRequest,
  getBookSingleTestRequest,
  removeUpdateBookTestsStatus,
  updateBookTestsStatus
} from "../../../store/Books/actions";
import { SET_OPEN_TEST_MODAL } from "../../../store/Utilities/types";
import Button from "../../Button";
import styles from "./TestPreview.scss";

export default function TestModal() {
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isProgram = pathname.includes("program");

  const dispatch = useDispatch();
  const { messages } = useIntl();
  const {
    test: {
      rightAnswer,
      wrongAnswer,
      score,
      explanation,
      notedescription,
      note,
      title,
      success,
      failed,
      mark,
      sectionscore,
      advice,
      testReport,
      pretest,
      posttest,
      pretestnotsolved,
      finaltest
    },
    buttons: { next, ok, submit }
  } = messages;

  const {
    showTestModal,
    testData: {
      testTypeQuestion,
      testType,
      setImageModal,
      markTestAsSolved,
      bookTest
    }
  } = useSelector((state) => state.utilities);

  const bookSingleTest = useSelector(
    (state) => state.books.bookSingleTest
  );

  const [testQuestionAnwseredView, setTestQuestionAnwseredView] =
    useState(false);
  const [notTestAllAnsweredOptionl, setNotTestAllAnsweredOptionl] =
    useState(false);

  const setImageModalToBook = (img) => {
    setImageModal({ src: img.src, title: img.title });
  };

  const getTotalScore = () => {
    const score = bookSingleTest
      ? bookSingleTest[`${testTypeQuestion}test_points`]
      : 0;
    return score ? score : 0;
  };

  const setOpenModal = (open) => {
    dispatch({
      type: SET_OPEN_TEST_MODAL,
      payload: open
    });
    if (!open) handleButtonClick(false);
  };

  const submitAnAnswer = async (questionId, answer) => {
    const payload = {
      test_question_id: questionId,
      test_answer_choice_id: answer,
      test_type: testType
    };
    await dispatch(answerBookSingleTestRequest(payload));
    dispatch(getBookSingleTestRequest({ id: bookTest.id }));
  };

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = (clicked) => {
    setButtonClicked(clicked);
  };

  useEffect(async () => {
    if (buttonClicked || !bookSingleTest?.id || !showTestModal) {
      return;
    }

    const fetchURL = (url) =>
      axiosInstance.get(
        `${
          isAuthor
            ? author_portal
            : isProgram
            ? program_director_portal
            : customer_portal
        }/${url}`
      );
    const testPromise = fetchURL(`tests/${bookSingleTest?.id}`);
    testPromise
      .then((promiseResult) => {
        setTestQuestionAnwseredView(
          testType == 1
            ? promiseResult.data.test?.test_questions?.every(
                (question) => !!question["post_test_user_answer"]
              )
            : promiseResult.data.test?.test_questions?.every(
                (question) => !!question["pre_test_user_answer"]
              )
        );
      })
      .catch((err) => {
        // Handle Error
      });
  }, [
    testQuestionAnwseredView,
    bookSingleTest?.id,
    buttonClicked,
    showTestModal
  ]);
  return (
    <>
      <Modal
        open={showTestModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...boxStyle,
            maxHeight: "90%",
            height: "90%",
            width: "45%",
            minWidth: "45%",
            overflowY: "auto"
          }}
        >
          <div
            className={
              testQuestionAnwseredView &&
              bookSingleTest?.sections_results.length > 0 &&
              testType === 1
                ? "relative bg-modal-image"
                : "relative"
            }
          >
            <div
              onClick={() => {
                setOpenModal(false);
              }}
              className="cursor-pointer d-flex justify-content-end"
            >
              <CloseButtonIcon />
            </div>
            {testType == 0 && <h2>{pretest}: </h2>}
            {testType == 1 && (
              <h2>
                {bookSingleTest.title
                  .toLowerCase()
                  .includes("final test")
                  ? finaltest
                  : posttest}
                :
              </h2>
            )}
            {testQuestionAnwseredView ? (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  className="text-center text-primary mb-4"
                >
                  {bookSingleTest?.title} {title}
                </Typography>
                <>
                  <div>
                    {50 <
                    (getTotalScore() / bookSingleTest?.total_points) *
                      100 ? (
                      <p className="success text-center my-4">
                        {success}
                      </p>
                    ) : (
                      <p className="error text-center my-4">
                        {failed}{" "}
                      </p>
                    )}
                    <div className="precentage mb-4">
                      <CircularProgressbar
                        value={Math.round(
                          (getTotalScore() /
                            bookSingleTest?.total_points) *
                            100
                        )}
                        text={`${Math.round(
                          (getTotalScore() /
                            bookSingleTest?.total_points) *
                            100
                        )}%`}
                      />
                    </div>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <div className=" mt-3 mb-4">
                        <Button
                          text={ok}
                          className="continue_btn"
                          onClick={() => {
                            setOpenModal(false);
                            setNotTestAllAnsweredOptionl(false);
                            setTestQuestionAnwseredView(false);
                            markTestAsSolved(bookSingleTest.id);

                            if (testType === 0)
                              dispatch(
                                removeUpdateBookTestsStatus({
                                  id: bookSingleTest.id,
                                  type: 0
                                })
                              );
                            if (testType === 1)
                              dispatch(
                                updateBookTestsStatus({
                                  id: bookSingleTest.id,
                                  type: testType
                                })
                              );
                          }}
                        />
                        <br />
                        <Button
                          text={"view test"}
                          className="continue_btn"
                          onClick={() => {
                            setTestQuestionAnwseredView(false);
                            handleButtonClick(true);
                          }}
                        />
                      </div>
                    </div>
                    {bookSingleTest?.sections_results.length > 0 &&
                      testType === 1 && (
                        <>
                          <Typography
                            variant="p"
                            className="text-gray my-4 d-block"
                          >
                            {testReport}
                          </Typography>{" "}
                          <div className="mt-2 table-container table-sections-results">
                            <table>
                              <tr>
                                <th>{mark}</th>
                                <th>{sectionscore}</th>
                                <th>{advice}</th>
                              </tr>

                              {bookSingleTest?.sections_results?.map(
                                (row, i) => (
                                  <>
                                    <tr key={i}>
                                      <td> {row.name}</td>

                                      <td> {row.percentage}</td>
                                      <td> {row.message}</td>
                                    </tr>
                                  </>
                                )
                              )}
                            </table>
                          </div>{" "}
                        </>
                      )}
                  </div>
                </>
              </>
            ) : (
              <>
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  className="text-center text-primary"
                >
                  {bookSingleTest?.title}
                </Typography>
                {testType === 1 && (
                  <div className="text-secondary my-3">
                    {score}: {getTotalScore()}/
                    {bookSingleTest?.total_points}
                  </div>
                )}
                {bookSingleTest?.test_questions?.find(
                  (qs) =>
                    !!qs?.image_url ||
                    qs?.test_answer_choices?.some(
                      (ans) => ans.image_url
                    )
                ) && (
                  <div className="note gray mb-3">
                    <span className="grey bold">{note} </span>
                    {notedescription}
                  </div>
                )}

                <div className="note text-primary mb-3">
                  {bookSingleTest?.note}
                </div>
                {bookSingleTest && (
                  <>
                    {bookSingleTest?.test_questions?.map(
                      (question, questionId) => (
                        <div className="mb-2" key={question.id}>
                          <div className=" mb-2">
                            <div className="d-flex align-items-baseline">
                              <h4 className="me-2 mb-0">
                                Q{questionId + 1}-
                              </h4>

                              <h4 className="mb-0 ">
                                {question.question}
                              </h4>
                            </div>

                            {question.image_url && (
                              <div
                                className="container_image_question mb-2"
                                onClick={() =>
                                  setImageModalToBook({
                                    src: question.image_url,
                                    title: `Q${questionId + 1}-${
                                      question.question
                                    }`
                                  })
                                }
                              >
                                <img
                                  src={question.image_url}
                                  className="img-thumbnail question-image"
                                />
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      src={Group}
                                      alt="medblend-image"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-center flex-column ">
                            <ol type="A">
                              {question?.test_answer_choices?.map(
                                (
                                  {
                                    answer,
                                    id: answerId,
                                    image_url,
                                    correct
                                  },
                                  index
                                ) => {
                                  return (
                                    <li key={index}>
                                      <div className="d-flex align-items-center">
                                        <div className="mb-2 answer">
                                          <label
                                            key={answerId}
                                            className="cursor-pointer d-flex align-items-start"
                                            htmlFor={
                                              !image_url
                                                ? `${question.id}_${answerId}`
                                                : ""
                                            }
                                          >
                                            <input
                                              id={`${question.id}_${answerId}`}
                                              type="radio"
                                              value={answer}
                                              disabled={
                                                question[
                                                  `${testTypeQuestion}test_user_answer`
                                                ] ||
                                                isAuthor ||
                                                isProgram
                                              }
                                              className="mt-2 mr-11"
                                              name={`question_${questionId}`}
                                              checked={
                                                question[
                                                  `${testTypeQuestion}test_user_answer`
                                                ]
                                                  ?.test_answer_choice_id ===
                                                answerId
                                              }
                                              onChange={() => {
                                                submitAnAnswer(
                                                  question.id,
                                                  answerId
                                                );
                                                if (
                                                  testType === 0 &&
                                                  !bookSingleTest.is_mandatory
                                                )
                                                  dispatch(
                                                    updateBookTestsStatus(
                                                      {
                                                        id: bookSingleTest.id,
                                                        type: testType
                                                      }
                                                    )
                                                  );
                                              }}
                                            />
                                            {image_url && (
                                              <div
                                                className="container_image_answer"
                                                onClick={() =>
                                                  setImageModalToBook(
                                                    {
                                                      src: image_url,
                                                      title: `${
                                                        index + 1
                                                      }-${answer}`
                                                    }
                                                  )
                                                }
                                              >
                                                <img
                                                  src={image_url}
                                                  width="100px"
                                                  className="img-thumbnail answer-image"
                                                  alt="answer"
                                                />
                                                <div className="middle">
                                                  <div className="text">
                                                    <img
                                                      height="20px"
                                                      src={Group}
                                                      alt="medblend-image"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            <span className="answer-text">
                                              {answer}
                                            </span>
                                          </label>
                                        </div>
                                        <div className="result">
                                          {question[
                                            `${testTypeQuestion}test_user_answer`
                                          ] && (
                                            <>
                                              {question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]?.correct &&
                                                question[
                                                  `${testTypeQuestion}test_user_answer`
                                                ]
                                                  ?.test_answer_choice_id ===
                                                  answerId &&
                                                testType === 1 && (
                                                  <div className="success">
                                                    {rightAnswer}
                                                  </div>
                                                )}

                                              {question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]
                                                ?.test_answer_choice_id ===
                                                answerId &&
                                              question[
                                                `${testTypeQuestion}test_user_answer`
                                              ]?.correct === false &&
                                              testType === 1 ? (
                                                <div className="error">
                                                  {wrongAnswer}
                                                </div>
                                              ) : (
                                                question[
                                                  `${testTypeQuestion}test_user_answer`
                                                ]?.correct ===
                                                  false &&
                                                correct &&
                                                testType === 1 && (
                                                  <div className="success">
                                                    {rightAnswer}
                                                  </div>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                            {!!question[
                              `${testTypeQuestion}test_user_answer`
                            ] &&
                              !!question?.justification &&
                              testType === 1 && (
                                <div className="explanation my-3">
                                  {explanation} :
                                  {question?.justification}
                                  {question?.justification_image_url && (
                                    <div style={{ width: "30vw" }}>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%"
                                        }}
                                        src={
                                          question?.justification_image_url
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      )
                    )}

                    {bookSingleTest?.test_questions.every(
                      (question) =>
                        question?.[
                          `${testTypeQuestion}test_user_answer`
                        ]
                    ) && (
                      <div className={styles["button-submit"]}>
                        {notTestAllAnsweredOptionl ? (
                          <Button
                            text={submit}
                            onClick={() => {
                              setTestQuestionAnwseredView(true);
                            }}
                          />
                        ) : (
                          <Button
                            text={next}
                            onClick={() => {
                              setOpenModal(false);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
