import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/FinalTest";
import {
  getFinalTestCustomerResponse,
  getFinalTestProgramerResponse,
  getFinalTestResponse,
  setNewFinalTestAnswerResponse
} from "./actions";
import { showHideSnackbar } from "../Snackbar/actions";
import messages from "../../assets/locale/messages";

export function* getFinalTestSaga({ payload }) {
  try {
    const response = yield call(apis.getFinalTest, payload);
    yield put(
      getFinalTestResponse({
        finalTest: response.data.final_tests
      })
    );
  } catch (err) {
    console.error(err);
  }
}

// export function* getFinalTestSaga({ payload }) {
//   try {
//     const { book_id, backend_URL } = payload;

//     const response = yield call(
//       apis.getFinalTestCustomerOrProgramer,
//       book_id,
//       backend_URL
//     );

//     yield put(
//       getFinalTestResponse({
//         finalTest: response.data.final_tests
//       })
//     );
//   } catch (err) {
//     console.error(err);
//   }
// }
export function* setNewAnswerFinalTestSaga({ payload }) {
  try {
    const { book_id, answers } = payload;

    const response = yield call(
      apis.setNewAnswerCustromerFinalTest,
      book_id,
      answers
    );

    yield put(
      setNewFinalTestAnswerResponse({
        resultOfAnswer: response.data.final_tests
      })
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].FinalTest.successfullySubmit
      })
    );
  } catch (err) {
    console.error(err);
  }
}
