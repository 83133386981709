import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import thumbnail from "../../assets/images/how-to-navigate-thumbnail.png";
import AboutUs from "../../components/AboutUsSection/index.jsx";
import Video from "../../components/Video/Video.jsx";
import { SET_IS_LOGGED_IN_AUTHOR } from "../../store/Utilities/types";
import AuthorsBooksListing from "../Books/BooksListing/AuthorsBooksListing";
import BooksListing from "../Books/BooksListing/BooksListing";
import EditorsBooksListing from "../Books/BooksListing/EditorsBooksListing";
import ProgramBooksListing from "../Books/BooksListing/ProgramBooksListing";
import SearchBooks from "../Books/BooksSearch";
import FeaturedBooks from "../Books/FeaturedBooks";
import ContactUs from "../ContactUs/index.jsx";
import Footer from "../Footer";
import { activeLinkClass } from "../Header/index.jsx";
import "./Home.scss";

const Home = () => {
  useEffect(() => {
    return () => {
      const links = document.getElementById("links");
      const otherLinks = links?.children ?? [];
      for (let i = 0; i < otherLinks.length; i++) {
        const element = otherLinks.item(i);
        element.classList.remove(activeLinkClass);
      }
    };
  }, []);
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const isEditor = pathname.includes("editor");
  const isAuthor = pathname.includes("author");
  const isUser = !isAuthor && !isEditor && !isProgram;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: SET_IS_LOGGED_IN_AUTHOR,
      payload: isAuthor
    });
  }, []);
  const { isLoggedInAuthor } = useSelector(
    (state) => state.utilities
  );
  const isAuthorOrEditor = isEditor || isAuthor;
  return (
    <>
      <section id="welcome-section-id">
        {isAuthorOrEditor && (
          <h1 className="fsize-30 p-3">
            {isEditor ? "Editor View" : "Author View"}
          </h1>
        )}
        {!isAuthorOrEditor && (
          <Container>
            <Row>
              <Col lg={8} md={12} className="mb-xs-40">
                <SearchBooks />
              </Col>
              <Col lg={4} md={12} className="mb-xs-40">
                <FeaturedBooks />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} className="mb-xs-40 mx-auto">
                <Video
                  path={
                    "https://acore-production.s3.amazonaws.com/Acore_fe/How+to+use+MedBlend.mp4"
                  }
                  thumbnail={thumbnail}
                />
              </Col>
            </Row>
          </Container>
        )}
      </section>
      {isLoggedInAuthor && (
        <AuthorsBooksListing
          items={10}
          isPaginated={true}
          hasFooter={false}
        />
      )}
      {isEditor && (
        <EditorsBooksListing
          items={10}
          isPaginated={true}
          hasFooter={false}
        />
      )}
      {isUser && (
        <BooksListing
          items={10}
          isPaginated={false}
          hasFooter={false}
        />
      )}
      {(isUser || isProgram) && (
        <ProgramBooksListing items={10} hasFooter={false} />
      )}
      <AboutUs />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
