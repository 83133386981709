import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ClickableBookCover from "../../../components/ClickableBookCover/ClickableBookCover";
import EmptyState from "../../../components/EmptyState";
import {
  getBooksAction,
  getRelatedBooksAction,
  setBooksFilters
} from "../../../store/Books/actions";
import { getCategoriesRequest } from "../../../store/Categories/actions";
import Filters from "./Filters";
import {
  getBooksListFromStore,
  getParams
} from "./BooksListingHelpers";
import { ROUTE_PATHS } from "../../../utils/PathsNames";
import Footer from "../../Footer";
import styles from "./BooksListing.module.scss";
import { BOOKS_LIST_TYPES } from "../../../utils/Constants";
import BackButton from "../../../components/BackButton";
import { capitalize } from "../../../utils/general";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BooksListing = ({
  items,
  isPaginated,
  fullLibrarySlug,
  hasFooter = true
}) => {
  const dispatch = useDispatch();
  const { listType, bookId } = useParams();
  const { messages } = useIntl();
  const [selectedBook, setSelectedBook] = useState(null);

  const booksFilters = useSelector((state) => state.books.filters);
  const isLoading = useSelector((state) => state.loader);
  const { booksList, metaData } = useSelector((state) =>
    getBooksListFromStore(state, listType)
  );

  const params = getParams(listType, items);
  const { pathname } = useLocation();
  const isEditor = pathname.includes("editor");
  const isProgram = pathname.includes("program");

  const {
    books: { viewTheFullLibrary },
    shared: { viewMore },
    header: { digitalCourses }
  } = messages;

  useEffect(() => {
    dispatch(getCategoriesRequest());
  }, []);
  const { isLoggedInAuthor } = useSelector(
    (state) => state.utilities
  );
  const handleLoadMore = () => {
    let payload = {
      ...params,
      page: metaData.currentPage + 1,
      list: booksList,
      isLoadMore: true,
      isAuthor: isLoggedInAuthor,
      isEditor
    };
    if (listType === BOOKS_LIST_TYPES.related) {
      payload = { id: bookId, params: { ...payload } };
      dispatch(getRelatedBooksAction(payload));
    } else {
      payload = { ...payload, ...booksFilters };
      dispatch(getBooksAction(payload));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        setBooksFilters({
          category_id: null,
          sorting_term: null,
          search_term: null
        })
      );
    };
  }, []);

  useEffect(() => {
    if (listType === BOOKS_LIST_TYPES.related) {
      const payload = { id: bookId, params };
      dispatch(getRelatedBooksAction(payload));
    }
  }, []);

  return (
    <section id="digital-books-section-id">
      <div className={styles["books-listing__container"]}>
        <Container>
          {listType && (
            <div className="d-flex gap-3 mb-5 mx-2">
              <BackButton />
              <h4 className="mt-2">{capitalize(listType)} Courses</h4>
            </div>
          )}
          {listType !== BOOKS_LIST_TYPES.related && (
            <div className="d-flex">
              <h3 style={{ paddingTop: "20px", width: "15rem" }}>
                {digitalCourses}
              </h3>
              <Filters items={items} />
            </div>
          )}
          <Row xs="2" md="2" xl="5" lg="4" className="pt-1">
            {!isLoading &&
              booksList?.length > 0 &&
              booksList?.map((book, index) => (
                <Col className="mb-5" key={book.id}>
                  <div
                    className={`position-relative ${styles.minHieghtBookClickable}`}
                  >
                    <ClickableBookCover
                      isSelected={selectedBook === book.id}
                      book={book}
                      index={index}
                      clearSelection={() => setSelectedBook(null)}
                      selectBook={() => setSelectedBook(book.id)}
                    />
                  </div>
                </Col>
              ))}
          </Row>
          {isPaginated ? (
            <>
              {metaData?.count > booksList?.length && (
                <div className={styles.listButtonWrapper}>
                  <button
                    onClick={handleLoadMore}
                    className={styles.listButton}
                  >
                    {viewMore}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className={styles.listButtonWrapper}>
              <Link
                to={fullLibrarySlug}
                className={styles.listButton}
              >
                {viewTheFullLibrary}
              </Link>
            </div>
          )}
          {!isLoading && !booksList?.length && <EmptyState />}
        </Container>
      </div>
      {hasFooter && (
        <>
          <Footer />
        </>
      )}
    </section>
  );
};

BooksListing.propTypes = {
  items: PropTypes.number,
  onClick: PropTypes.func,
  direction: PropTypes.string,
  isPaginated: PropTypes.bool,
  hasFooter: PropTypes.bool,

  fullLibrarySlug: PropTypes.string
};

BooksListing.defaultProps = {
  items: 10,
  isPaginated: true,
  fullLibrarySlug: ROUTE_PATHS["books"]
};

export default BooksListing;
