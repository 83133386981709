import {
  axiosInstance,
  customer_portal,
  program_director_portal
} from ".";

export const addCustomerComment = async ({
  programId,
  topicId,
  comment
}) =>
  await axiosInstance.post(
    `${customer_portal}/programs/${programId}/topics/${topicId}/comments`,
    comment
  );
export const deleteCustomerComment = async ({
  programId,
  topicId,
  commentId
}) =>
  await axiosInstance.delete(
    `${customer_portal}/programs/${programId}/topics/${topicId}/comments/${commentId}`
  );

export const addDirectorComment = async ({
  programId,
  topicId,
  comment
}) =>
  await axiosInstance.post(
    `${program_director_portal}/programs/${programId}/topics/${topicId}/comments`,
    comment
  );

export const deleteDirectorComment = async ({
  programId,
  topicId,
  commentId
}) =>
  await axiosInstance.delete(
    `${program_director_portal}/programs/${programId}/topics/${topicId}/comments/${commentId}`
  );
