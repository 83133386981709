export const GET_BOOKS = "GET_BOOKS";
export const GET_USER_PROGRAMS = "GET_USER_PROGRAMS";
export const SET_USER_PROGRAMS = "SET_USER_PROGRAMS";
export const SET_BOOKS = "SET_BOOKS";
export const SET_BOOKS_FEATURED = "SET_BOOKS_FEATURED";
export const SET_BOOKS_FILTERS = "SET_BOOKS_FILTERS";
export const GET_RELATED_BOOKS = "GET_RELATED_BOOKS";
export const SET_RELATED_BOOKS = "SET_RELATED_BOOKS";
export const GET_SINGLE_BOOK = "GET_SINGLE_BOOK";
export const GET_AUTHOR_SINGLE_BOOK = "GET_AUTHOR_SINGLE_BOOK";
export const GET_EDITOR_SINGLE_BOOK = "GET_EDITOR_SINGLE_BOOK";
export const GET_DIRECTOR_SINGLE_BOOK = "GET_DIRECTOR_SINGLE_BOOK";
export const GET_AUTHOR_ASSIGNED_BOOKS = "GET_AUTHOR_ASSIGNED_BOOKS";
export const GET_EDITOR_ASSIGNED_BOOKS = "GET_EDITOR_ASSIGNED_BOOKS";
export const SET_CURRENT_SUBSCRIPTION_LOADER =
  "SET_CURRENT_SUBSCRIPTION_LOADER";
export const SET_SINGLE_BOOK = "SET_SINGLE_BOOK";
export const GET_SINGLE_BOOK_SUPPLEMENTRAIES =
  "GET_SINGLE_BOOK_SUPPLEMENTRAIES";
export const SET_SINGLE_BOOK_SUPPLEMENTRAIES =
  "SET_SINGLE_BOOK_SUPPLEMENTRAIES";
export const SET_SUBSCRIBE_BOOK_SELECTED =
  "SET_SUBSCRIBE_BOOK_SELECTED";
export const FAKE_SUBSCIBE_BOOK = "FAKE_SUBSCIBE_BOOK";
export const UNSUBSCIBE_BOOK = "FAKE_UNSUBSCIBE_BOOK";
export const SET_SUBSCIBED_BOOKS_ACTION =
  "SET_SUBSCIBED_BOOKS_ACTION";
export const ADD_SUBSCIBED_BOOK_ACTION = "ADD_SUBSCIBED_BOOK_ACTION";
export const GET_SUBSCRIBED_BOOKS_REQUEST =
  "GET_SUBSCRIBED_BOOKS_REQUEST";
export const GET_SUBSCRIBED_BOOKS = "GET_SUBSCRIBED_BOOKS";

export const GET_SUBSCRIBED_BOOKS_RESPONSE =
  "GET_SUBSCRIBED_BOOKS_RESPONSE";

export const SET_SUBSCRIBED_BOOKS_IDS = "SET_SUBSCRIBED_BOOKS_IDS";
export const UPDATE_BOOK_PROGRESS = "UPDATE_BOOK_PROGRESS";
export const UPDATE_BOOK_SUPPLEMENTARY_PROGRESS =
  "UPDATE_BOOK_SUPPLEMENTARY_PROGRESS";
export const GET_BOOK_SUPPLEMENTARY_PROGRESS =
  "GET_BOOK_SUPPLEMENTARY_PROGRESS";
export const SET_BOOK_SUPPLEMENTARY_PROGRESS =
  "SET_BOOK_SUPPLEMENTARY_PROGRESS";
export const GET_BOOK_QUIZZES = "GET_BOOK_QUIZZES";
export const SET_BOOK_QUIZZES = "SET_BOOK_QUIZZES";
export const GET_CERTAIN_QUIZ = "GET_CERTAIN_QUIZ";
export const SET_CERTAIN_QUIZ = "SET_CERTAIN_QUIZ";

export const GET_BOOK_TESTS_REQUEST = "GET_BOOK_TESTS_REQUEST";
export const GET_BOOK_TESTS_RESPONSE = "GET_BOOK_TESTS_RESPONSE";

export const GET_BOOK_SINGLE_TEST_REQUEST =
  "GET_BOOK_SINGLE_TEST_REQUEST";
export const GET_BOOK_SINGLE_TEST_RESPONSE =
  "GET_BOOK_SINGLE_TEST_RESPONSE";

export const ANSWER_BOOK_SINGLE_TEST_REQUEST =
  "ANSWER_BOOK_SINGLE_TEST_REQUEST";
export const ANSWER_BOOK_SINGLE_TEST_RESPONSE =
  "ANSWER_BOOK_SINGLE_TEST_RESPONSE";

export const CREATE_BOOK_TESTS_STATUS = "CREATE_BOOK_TESTS_STATUS";
export const UPDATE_BOOK_TESTS_STATUS = "UPDATE_BOOK_TESTS_STATUS";

export const GET_CERTIFICATE_RESPONSE = "GET_CERTIFICATE_RESPONSE";
export const GET_CERTIFICATE_REQUEST = "GET_CERTIFICATE_REQUEST";
export const GET_INSTITUTION_BOOKS_REQUEST =
  "GET_INSTITUTION_BOOKS_REQUEST";

export const GET_INSTITUTION_BOOKS_RESPONSE =
  "GET_INSTITUTION_BOOKS_RESPONSE";

export const SET_EDITOR_NOTES = "SET_EDITOR_NOTES";
export const GET_EDITOR_NOTES = "GET_EDITOR_NOTES";
export const GET_DIRECTOR_NOTES = "GET_DIRECTOR_NOTES";

export const REMOVE_UPDATE_BOOK_TESTS_STATUS =
  "REMOVE_UPDATE_BOOK_TESTS_STATUS";
